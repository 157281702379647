import { itemsAPI } from '../api/api';
import { showNotification } from '../parts/Admin/utils/notifications/notifications';

const SET_ASSESSMENTS_LAYERS = 'SET_ASSESSMENTS_LAYERS';
const SET_EDITING_ITEM_ID = 'SET_EDITING_ITEM_ID';
const REMOVE_EDITING_ITEM_ID = 'REMOVE_EDITING_ITEM_ID';
const UPDATE_ASSESSMENTS_POSITION = 'UPDATE_ASSESSMENTS_POSITION';
const SET_ASSESSMENT_CREATION_TYPE = 'SET_ASSESSMENT_CREATION_TYPE';
const SET_STEP = 'SET_STEP';
const SET_ASSESSMENTS_LIST = 'SET_ASSESSMENTS_LIST';
const SET_LOADING_STATE = 'SET_LOADING_STATE';
const ASSESSMENT_CREATION_START = 'ASSESSMENT_CREATION_START';
const ASSESSMENT_CREATION_END = 'ASSESSMENT_CREATION_END';
const DELETE_ASSESSMENTS = 'DELETE_ASSESSMENT';
const SET_ASSESSMENT_MAP = 'SET_ASSESSMENT_MAP';
const UPDATE_ASSESSMENT_MAP = 'UPDATE_ASSESSMENT_MAP';
const DELETE_ASSESSMENT_MAP = 'DELETE_ASSESSMENT_MAP';
// const SET_IS_LOADED = 'SET_IS_LOADED';

const ENTITY = 'self-assessment';

const initialState = {
  activeItemId: null,
  newAssessmentCreation: false,
  assessmentCreationActiveStep: 2,
  assessmentCreationType: 'self-assessment',
  itemsLoading: true,
  items: [],
  // isLoaded: true,
};

const selfAssessmentComponentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ASSESSMENTS_LIST:
      // debugger
      return {
        ...state,
        items: action.payload
      };
    case SET_LOADING_STATE:
      return {
        ...state,
        itemsLoading: action.payload
      };
    // case SET_IS_LOADED:
    //   return {
    //     ...state,
    //     isLoaded: action.payload
    //   };
    // case SET_ITEMS_LAYERS:
    //   return {
    //     ...state,
    //     items: action.payload
    //   };
    case SET_EDITING_ITEM_ID:
      return {
        ...state,
        activeItemId: action.payload
      };
    case SET_ASSESSMENT_MAP:
      const tmpState = {
        ...state
      }
      tmpState.items.push(action.payload);

      return {
        ...state,
        items: [...tmpState.items]
      };
    case UPDATE_ASSESSMENT_MAP:
      const tmpState1 = {
        ...state
      }
      const newField = tmpState1.items.find(field => field.id === action.payload.id)
      const index = tmpState1.items.indexOf(newField)
      newField.name = action.payload.name
      newField.type = action.payload.type
      newField.content = action.payload.content
      newField.id = action.payload.id
      newField.active = action.payload.active
      newField.position = action.payload.position

      tmpState1.items[index] = newField
      return {
        ...state,
        items: [...tmpState1.items]
      }
    case DELETE_ASSESSMENT_MAP:
      const tmpState2 = {
        ...state
      }
      const newFields = tmpState2.items.filter((field) => field.id !== action.payload);
      tmpState2.items = newFields
      return {
        ...state,
        items: [...tmpState2.items]
      }
    case REMOVE_EDITING_ITEM_ID:
      return {
        ...state,
        activeItemId: null
      };
    case ASSESSMENT_CREATION_START:
      return {
        ...state,
        newAssessmentCreation: true
      };
    case UPDATE_ASSESSMENTS_POSITION:
      return {
        ...state,
        items: action.payload.map((item, index) => {
          item.position = index;
          return item;
        })
      };
    case ASSESSMENT_CREATION_END:
      return {
        ...state,
        newAssessmentCreation: false,
        assessmentCreationActiveStep: 2,
        assessmentCreationType: 'self-assessment'
      };
    case SET_ASSESSMENT_CREATION_TYPE:
      return {
        ...state,
        assessmentCreationType: action.layerType
      };
    case SET_STEP:
      return {
        ...state,
        assessmentCreationActiveStep: action.step
      };

    case DELETE_ASSESSMENTS:
      return {
        ...state,
        items: state.items.filter((_, index) => index !== action.payload)
      };
    default:
      return state;
  }
};

export const setAssessmentsList = (layers) => ({
  type: SET_ASSESSMENTS_LIST,
  payload: layers
});


export const setAssessmentMap = (data) => ({
  type: SET_ASSESSMENT_MAP,
  payload: data
});
//
export const updateAssessmentMap = (data) => ({
  type: UPDATE_ASSESSMENT_MAP,
  payload: data
});

export const deleteAssessmentMap = (data) => ({
  type: DELETE_ASSESSMENT_MAP,
  payload: data
});

export const setEditingItemId = (layerId) => ({
  type: SET_EDITING_ITEM_ID,
  payload: layerId
});

export const removeEditingItemId = () => ({
  type: REMOVE_EDITING_ITEM_ID
});

export const startAssessmentsCreation = () => ({
  type: ASSESSMENT_CREATION_START
});

export const endAssessmentCreation = () => ({
  type: ASSESSMENT_CREATION_END
});

export const setStep = (step) => ({
  type: SET_STEP,
  step
});

export const setAssessmentCreationType = (layerType) => ({
  type: SET_ASSESSMENT_CREATION_TYPE,
  layerType
});

export const setLoadingState = (bool) => ({
  type: SET_LOADING_STATE,
  payload: bool
});

// export const setIsLoaded = (bool) => ({
//   type: SET_IS_LOADED,
//   payload: bool
// });

export const deleteItemByIndex = (index) => ({
  type: DELETE_ASSESSMENTS,
  payload: index
});

export const updateAssessmentsPosition = (items) => ({
  type: UPDATE_ASSESSMENTS_POSITION,
  payload: items
});

export const fetchAssessments = (mapId) => {
  return async (dispatch) => {
    try {
      const response = await itemsAPI.getItemData(ENTITY, mapId);
// debugger
      dispatch(setAssessmentsList(response.items || []));
    } catch (error) {
      console.log(error);
      dispatch(setAssessmentsList([]));
    } finally {
      setTimeout(function () {
        dispatch(setLoadingState(false));
      }, 700);

    }
  };
};

export const deleteItem = (index) => {
  return async (dispatch) => {
    try {
      dispatch(deleteItemByIndex(index));
    } catch (error) {
      if (error.response?.data?.message) {
        showNotification(error.response.data.message, 'danger', 'shifted');
      } else {
        showNotification('Some error occurred', 'danger', 'shifted');
      }
      console.log(error);
    } finally {
    }
  };
};

export default selfAssessmentComponentsReducer;
