import {OPEN_SHARE_MODAL, CLOSE_SHARE_MODAL} from "./types";

const initialState = {
    isShareModal: false,
};

const shareReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_SHARE_MODAL:
            return {...state, isShareModal: true};
        case CLOSE_SHARE_MODAL:
            return {...state, isShareModal: false};
            default: return state;
        }
}

export default shareReducer;