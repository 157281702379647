import { userAPI, itemsAPI } from '../api/api';
import { showNotification } from '../parts/Admin/utils/notifications/notifications';
import history from '../history';
import { getInvitedUsers, setLoginFormLoading } from "./auth-reducer";
import { getItems, errorMessage, successMessage } from './actions';

const SET_OPEN = 'SET_OPEN';


const ENTITY = 'users';
const ENTITY_UPPER_CASE = ENTITY.toUpperCase();

const initialState = {
    items: null,
    itemsLoading: true,
    totalCount: null,
    filteredCount: null,
    item: null,
    currentPage: 1,
    pageSize: '8',
    sortField: 'username',
    sortType: 'asc',
    filterString: '',
    isFilterOpen: false,
    usersList: [],
    selectedUser: null,
};

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SET_ITEMS_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                items: action.data.items,
                totalCount: action.data.total_count,
                filteredCount: action.data.filtered_count
            };
        case `SET_ITEM_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                item: action.data
            };
        case `SET_CURRENT_PAGE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                currentPage: action.currentPage
            };
        case `SET_FILTER_OPEN_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                isFilterOpen: action.isFilterOpen,
            }
        case `USERS_LIST_SET_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                usersList: action.data
            };
        case `SELECTED_USER_SET_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                selectedUser: action.data
            };
        case `CHANGE_SORT_${ENTITY_UPPER_CASE}`:
            let newSortType = '';

            if (action.oldSortField === action.sortField) {
                if (action.oldSortType === 'asc') {
                    newSortType = 'desc';
                } else {
                    newSortType = 'asc';
                }
            } else {
                newSortType = 'desc';
            }

            return {
                ...state,
                sortField: action.sortField,
                sortType: newSortType
            };
        case `SET_FILTER_STRING_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                filterString: action.filterString
            };
        case `SET_PAGE_SIZE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                pageSize: action.pageSize
            };
        default:
            return state;
    }
};

export const changePassword = (values) => {
    return (dispatch, getState) => {
        userAPI
            .changePassword(values)
            .then((data) => {
                showNotification('Password successfully changed', 'success');

                //history.push('/admiral-admin/login')
            })
            .catch((error) => {
                (error.response?.data.message).forEach((value, i) => {
                    showNotification(error.response?.data.message[i], 'danger');
                });
            })
            .finally(() => { });
    };
};

export const updateItem = (entity, id, formData) => {
    return async (dispatch) => {
        try {
            let data = await itemsAPI.updateUser(entity, id, formData);

            dispatch(getItems(entity));
            // dispatch(getItemData(entity,id));
            successMessage(data)
            history.push(`/admiral-admin/${entity}`);
        } catch (error) {
            errorMessage(error)
        } finally {
        }
    };
};

export const loginInvite = (values) => {
    return (dispatch, getState) => {
        dispatch(setLoginFormLoading(true));
        userAPI
            .loginInvite(values)
            .then((data) => {
                history.push('/admiral-admin/login');
                successMessage(data);
                // showNotification(data, 'success');
            })
            .catch((error) => {
                console.log(error)
                errorMessage(error)
            })
            .finally(() => {
                dispatch(setLoginFormLoading(false));
            });
    };
};

export const verify = (hash) => {
    return (dispatch, getState) => {

        // dispatch(setLoginFormLoading(true));
        userAPI
            .verify(hash)
            .then((data) => {
                // history.push('/admiral-admin/login');
                // showNotification(data.result, 'success');
            })
            .catch((error) => {
                console.log(error)
                // (error.response?.data.message).forEach((value, i) => {
                //     showNotification(error.response?.data.message[i], 'danger');
                // });
            })
            .finally(() => {
                // dispatch(setLoginFormLoading(false));
            });
    };
};


export const inviteUser = (values, setInviteModalIsOpen) => {
    return (dispatch, getState) => {


        userAPI
            .inviteUser(values)
            .then((data) => {
                if (setInviteModalIsOpen) {
                    setInviteModalIsOpen(false)
                }
                showNotification("Invitation link was send. Don't forget to check spam folder if you can't find it.", 'success');
            })
            .catch((error) => {
                (error.response?.data.message).forEach((value, i) => {
                    showNotification(error.response?.data.message[i], 'danger');
                });
            })
            .finally(() => { });
    };
};

export const deleteInvitedUser = (email) => {
    return (dispatch, getState) => {
        userAPI
            .deleteInvitedUser(email)
            .then((data) => {
                dispatch(getInvitedUsers())
                successMessage(data);
            })
            .catch((error) => {
                errorMessage(error)
            })
            .finally(() => { });
    };
};


export default usersReducer;
