import { itemsAPI } from "../api/api";
import { showNotification } from '../parts/Admin/utils/notifications/notifications';
import history from '../history';
import { getItems, errorMessage, successMessage, getItemData } from './actions';
import { toast } from "react-toastify";

const SET_QUIZZES_STATISTICS_DATA = 'SET_QUIZZES_STATISTICS_DATA';
const SET_SPECIFIC_QUIZ_STATISTICS_DATA = 'SET_SPECIFIC_QUIZ_STATISTICS_DATA';
const SET_SELECTED_QUIZ_NAME = 'SET_SELECTED_QUIZ_NAME';


const ENTITY = 'quizzes';
const ENTITY_UPPER_CASE = ENTITY.toUpperCase();


const initialState = {
    items: null,
    itemsLoading: true,
    totalCount: null,
    filteredCount: null,
    item: null,
    currentPage: 1,
    pageSize: '8',
    sortField: 'create_date',
    sortType: 'desc',
    filterString: '',
    isFilterOpen: false,
    usersList: [],
    selectedUser: null,
    quizzesStatisticsData: [],
    specificQuizStatisticsData: [],
    selectedQuizName: '',
}

const quizzesReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SET_ITEMS_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                items: action.data.items,
                totalCount: action.data.total_count,
                filteredCount: action.data.filtered_count
            }
        case `SET_ITEM_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                item: action.data
            }
        case `SET_CURRENT_PAGE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                currentPage: action.currentPage,
            }
        case `SET_FILTER_OPEN_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                isFilterOpen: action.isFilterOpen,
            }
        case `USERS_LIST_SET_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                usersList: action.data
            };
        case `SELECTED_USER_SET_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                selectedUser: action.data
            };
        case SET_QUIZZES_STATISTICS_DATA:
            return {
                ...state,
                quizzesStatisticsData: action.payload,
            };
        case SET_SPECIFIC_QUIZ_STATISTICS_DATA:
            return {
                ...state,
                specificQuizStatisticsData: action.payload
            };
        case SET_SELECTED_QUIZ_NAME:
            return {
                ...state,
                selectedQuizName: action.payload
            };

        case `CHANGE_SORT_${ENTITY_UPPER_CASE}`:
            let newSortType = '';

            if (action.oldSortField === action.sortField) {
                if (action.oldSortType === 'asc') {
                    newSortType = 'desc';
                } else {
                    newSortType = 'asc';
                }
            } else {
                newSortType = 'desc';
            }

            return {
                ...state,
                sortField: action.sortField,
                sortType: newSortType,
            }
        case `SET_FILTER_STRING_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                filterString: action.filterString
            }
        case `SET_PAGE_SIZE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                pageSize: action.pageSize
            }
        default:
            return state;
    }
}

export const setQuizzesStatisticsData = (payload) => ({
    type: SET_QUIZZES_STATISTICS_DATA,
    payload
});

export const setSpecificQuizStatisticsData = (payload) => ({
    type: SET_SPECIFIC_QUIZ_STATISTICS_DATA,
    payload
});

export const setSelectedQuizName = (payload) => ({
    type: SET_SELECTED_QUIZ_NAME,
    payload
});

export const updateItem = (entity, id, item, components, apply) => {
    return async (dispatch) => {
        try {
            let data = await itemsAPI.updateQuiz(entity, id, item, components);
            dispatch(getItems(entity));
            successMessage(data)
    
            if(!apply){
            history.push(`/admiral-admin/${entity}`);
            } else {
                dispatch(getItemData(entity,id))
            }
        } catch (error) {
            // errorMessage(error)
        } finally {
        }
    }
};

export const createItem = (entity, values, components) => {
    return async (dispatch) => {
        try {
            let data = await itemsAPI.createQuiz(entity, values, components)
            dispatch(getItems(ENTITY));
            successMessage(data)
            history.push(`/admiral-admin/${ENTITY}`);
        } catch (error) {
            // errorMessage(error)
        } finally {
        }
    }
};


export const getQuizzesStatistics = () => {
    return async (dispatch) => {
        try {
            let data = await itemsAPI.getQuizzesStatistics()
            dispatch(setQuizzesStatisticsData(data));
            // successMessage(data)
            // history.push(`/admiral-admin/${ENTITY}`);
        } catch (error) {
            // errorMessage(error)
        } finally {
        }
    }
}


export const specificQuizStatistics = (id) => {
    return async (dispatch) => {
        try {
            let data = await itemsAPI.specificQuizStatistics(id)
            dispatch(setSpecificQuizStatisticsData(data.data));
            dispatch(setSelectedQuizName(data.name));
            // successMessage(data)
            // history.push(`/admiral-admin/${ENTITY}`);
        } catch (error) {
            errorMessage(error)
            dispatch(setSpecificQuizStatisticsData([]));
            dispatch(setSelectedQuizName(''));
        } finally {
        }
    }
}

export default quizzesReducer;