import { pagesTableAPI } from '../api/api';
import { showNotification } from '../parts/Admin/utils/notifications/notifications';
import history from '../history'
import { fetchPagesData, getPagesData } from "./pages-reducer";
import { setFilterString, changeSortActionCreator } from './actions';
import { errorMessage, successMessage } from './actions';

const ENTITY = 'pages';
const ENTITY_UPPER_CASE = ENTITY.toUpperCase();

const initialState = {
    items: null,
    itemsLoading: true,
    totalCount: null,
    item: null,
    currentPage: 1,
    pageSize: '30',
    sortField: 'create_date',
    sortType: 'asc',
    filterString: '',
    breadcrumbs: [
        {
            name: 'Dashboard',
            link: '/admiral-admin'
        },
        {
            name: 'Pages',
            link: false
        }
    ]
}

const pagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SET_ITEMS_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                items: action.data.items,
                totalCount: action.data.filtered_count,
            }
        case `SET_ITEM_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                item: action.data
            }
        case `SET_CURRENT_PAGE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                currentPage: action.currentPage,
            }
        case `CHANGE_SORT_${ENTITY_UPPER_CASE}`:
            let newSortType = '';

            if (action.oldSortField === action.sortField) {
                if (action.oldSortType === 'asc') {
                    newSortType = 'desc';
                } else {
                    newSortType = 'asc';
                }
            } else {
                newSortType = 'desc';
            }

            return {
                ...state,
                sortField: action.sortField,
                sortType: newSortType,
            }
        case `SET_FILTER_STRING_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                filterString: action.filterString
            }
        case `SET_PAGE_SIZE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                pageSize: action.pageSize
            }
        case `SET_BREADCRUMBS_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                breadcrumbs: action.breadcrumbs
            }
        default:
            return state;
    }
}

export const changeSort = (oldSortField, oldSortType, sortField) => {
    return (dispatch, getState) => {
        dispatch(changeSortActionCreator(ENTITY_UPPER_CASE, oldSortField, oldSortType, sortField));
        dispatch(fetchPagesData(getState().pages.activePageId));
    }
};

export const setActive = (id) => {
    return async (dispatch, getState) => {
        try {
            let data = await pagesTableAPI.setActive(ENTITY, id)
            // showNotification(data.message, 'success', 'shifted');
            successMessage(data)
            dispatch(fetchPagesData(getState().pages.activePageId));
            history.push('/admiral-admin/pages');
        } catch (error) {
            errorMessage(error)
        } finally {

        }

    }
};

export const deleteItem = (id) => {
    return async (dispatch, getState) => {
        try {
            let data = await pagesTableAPI.deleteItem(ENTITY, id)
            showNotification(data.message, 'success', 'shifted');
            dispatch(fetchPagesData(getState().pages.activePageId));
            history.push('/admiral-admin/pages');
        } catch (error) {
            if (error.data?.message) {
                showNotification(error.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error)
        } finally {

        }

    }
};

export const createItem = ({ name }) => {
    return async (dispatch, getState) => {
        try {
            let data = await pagesTableAPI.createItem(ENTITY, { name })
            successMessage(data)
            dispatch(fetchPagesData(getState().pages.activePageId));
            history.push('/admiral-admin/pages');
        } catch (error) {
            if (Array.isArray(error.data?.message)) {
                let message = '';
                error.data.message.map(m => {
                    message += `${m} `;
                });
                showNotification(message, 'danger', 'shifted');
            } else if (error.data?.message) {
                showNotification(error.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error)
        } finally {

        }
    }
};

export const updateItem = ({ name, id }) => {
    return async (dispatch, getState) => {
        try {
            let data = await pagesTableAPI.updateItem(ENTITY, { name, id })
            dispatch(fetchPagesData(getState().pages.activePageId));
            showNotification(data.message, 'success', 'shifted');
            history.push('/admiral-admin/pages');
        } catch (error) {
            if (Array.isArray(error.data?.message)) {
                let message = '';
                error.data.message.map(m => {
                    message += `${m} `;
                });
                showNotification(message, 'danger', 'shifted');
            } else if (error.data?.message) {
                showNotification(error.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error)
        } finally {
        }
    }
};

export const applyFilter = (entity, values) => {
    return (dispatch, getState) => {
        let filterString = '';

        let fltStrProps = '&flt_by_props='
        let fltStrValues = '&flt_by_values='

        if (values) {
            if (values.active) {
                if (values.active.value === 2)
                    values.active = 1
                if (values.active.value === 3)
                    values.active = 0
            }
            if (values.default_item) {
                if (values.default_item.value === 2)
                    values.default_item = 1
                if (values.default_item.value === 3)
                    values.default_item = 0
            }
            if (values.create_user_id) {
                values.create_user_id = values.create_user_id.value
            }
            if (values.type) {
                values.type = values.type.value
            }
            if (values.update_user_id) {
                values.update_user_id = values.update_user_id.value
            }
            if (values.language_id) {
                values.language_id = values.language_id.value
            }
            let itemKeys = Object.keys(values);
            let itemValues = Object.values(values);

            for (let i = 0; i < itemKeys.length; i++) {
                if (i === itemKeys.length - 1) {
                    fltStrProps += `${itemKeys[i]}`
                    fltStrValues += `${itemValues[i]}`
                } else {
                    fltStrProps += `${itemKeys[i]},`
                    fltStrValues += `${itemValues[i]},`
                }
            }
            filterString += `${fltStrProps}${fltStrValues}&flt_date_operator=range`
        }

        dispatch(setFilterString(ENTITY_UPPER_CASE, filterString));
        dispatch(getPagesData(null));
    }
};

export default pagesReducer;