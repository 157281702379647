import './App.css';
import React, { Suspense, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import { Helmet } from 'react-helmet';



const Admin = React.lazy(() => import('./parts/Admin/Admin'));
const Public = React.lazy(() => import('./parts/Public/Public'));


const App = () => {
    return (
        <>

            <Router history={history}>
                <Helmet>
                    <title>Ehra</title>
                    <meta name="description" content="Eurasian Harm Reduction Association (EHRA) is a non-for-profit public membership-based organisation, registered by the initiative of harm reduction activists and organisations from Central and Eastern Europe and Central Asia (CEECA) in 2017" />
                    <meta property="og:title" content="Ehra" />
                    <meta property="og:description" content="Eurasian Harm Reduction Association (EHRA) is a non-for-profit public membership-based organisation, registered by the initiative of harm reduction activists and organisations from Central and Eastern Europe and Central Asia (CEECA) in 2017" />
                    <meta property="og:image" content="https://api.harmreductioneurasia.org/2825283a-6d85-4c99-b6b2-151cc337f410.png" />
                </Helmet>
                <Suspense fallback={<div></div>}>
                    <Switch>
                        <Route
                            path="/admiral-admin"
                            render={() => (
                                <Admin />
                            )}
                        />

                        <Route
                            path="/"
                            render={() => (
                                <Public />
                            )}
                        />
                    </Switch>
                </Suspense>
            </Router>
        </>
    );
}

export default App;