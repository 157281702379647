import { selectedLanguageSet, setActivePageId } from './pages-reducer';
import { itemsAPI, menusApi, pageInfoAPI } from '../api/api';
import { showNotification } from '../parts/Admin/utils/notifications/notifications';
import { setItem, errorMessage, successMessage } from './actions';

const MENUS_ITEMS_DATA_SET = 'MENUS_ITEMS_DATA_SET';
const ACTIVE_MENU_ITEM_ID = 'ACTIVE_MENU_ITEM_ID';

const MENUS_ITEMS_DATA_EDIT = 'MENUS_ITEMS_DATA_EDIT';
const MENU_ITEMS_SET_HIDDEN = 'MENU_ITEMS_SET_HIDDEN';
const PAGES_SET = 'PAGES_SET';
const SET_MENU_ID = 'SET_MENU_ID';
const IS_FROM_PAGES_SET = 'IS_FROM_PAGES_SET';
const LANGUAGES_LIST_SET = 'LANGUAGES_LIST_SET';
const SELECTED_MENU_PART_SET = 'SELECTED_MENU_PART_SET';
const SELECTED_MENU_ITEM_LANGUAGE_SET = 'SELECTED_MENU_ITEM_LANGUAGE_SET';
const SET_MODAL_OPEN = 'SET_MODAL_OPEN';
const ENTITY = 'menus';

const initialState = {
    items: [],
    parentLoadingId: null,
    activeMenuItemId: null,
    menuItemsDataLoading: false,
    pages: [],
    itemsHidden: [],
    menuId: null,
    languagesList: [],
    selectedLanguage: null,
    selectedMenuPart: {
        label: 'Main header',
        value: 'main_header'
    },
    isModalOpen: false,
};

const menuItemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_FROM_PAGES_SET:
            return {
                ...state,
                isFromPages: action.bool
            };
        case SET_MENU_ID:
            return {
                ...state,
                menuId: action.data
            };
        case PAGES_SET:
            return {
                ...state,
                pages: action.data
            };

        case MENUS_ITEMS_DATA_EDIT:
            return {
                ...state,
                items: [...action.data]
            };
        case MENUS_ITEMS_DATA_SET:
            return {
                ...state,
                items: [action.data, ...state.items]
            };
        case ACTIVE_MENU_ITEM_ID:
            return {
                ...state,
                activeMenuItemId: action.data
            };
        case SELECTED_MENU_ITEM_LANGUAGE_SET:
            return {
                ...state,
                selectedLanguage: action.data
            };
        case SELECTED_MENU_PART_SET:
            return {
                ...state,
                selectedMenuPart: action.data
            };
        case LANGUAGES_LIST_SET:
            return {
                ...state,
                languagesList: action.data
            };
        case SET_MODAL_OPEN: 
            return {
                ...state,
                isModalOpen: action.bool
            }
        default:
            return state;
    }
};

function makeActionCreator(type, ...argNames) {
    return function (...args) {
        let action = { type };
        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index];
        });
        return action;
    };
}

export const setModalOpen = makeActionCreator(SET_MODAL_OPEN, 'bool')
export const menuItemsDataSet = makeActionCreator(MENUS_ITEMS_DATA_SET, 'data');
export const menuItemsDataEdit = makeActionCreator(MENUS_ITEMS_DATA_EDIT, 'data');
const setPages = makeActionCreator(PAGES_SET, 'data');
export const setActiveMenuItemId = makeActionCreator(ACTIVE_MENU_ITEM_ID, 'data');
export const setMenuId = makeActionCreator(SET_MENU_ID, 'data');
const languagesListSet = makeActionCreator(LANGUAGES_LIST_SET, 'data');
export const selectedMenuPartSet = makeActionCreator(SELECTED_MENU_PART_SET, 'data');
export const selectedMenuItemLanguageSet = makeActionCreator(SELECTED_MENU_ITEM_LANGUAGE_SET, 'data');

export const getMenuPagesItems = (items) => {
    return async (dispatch, getState) => {
        try {
            let data = await menusApi.getPages();

            dispatch(setPages(data));
        } catch (error) {
            console.log(error);
        } finally {
        }
    };
};

export const getMenuItemData = (entity, id, setName, setLanguage, setMenuPart) => {
    return async (dispatch) => {
        try {
            let data = await itemsAPI.getItemData(entity, id);
            dispatch(setItem(entity.toUpperCase(), data));
            dispatch(menuItemsDataEdit(data.content));
            setName(data.name);
            setLanguage(data.language_id)
            setMenuPart(data.menu_part)
        } catch (error) {
            if (error.data?.message) {
                showNotification(error.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
        }
    };
};

export const deleteMenuItem = (id, target, menuId, goBack = false) => {
    return async (dispatch, getState) => {
        try {
            let itemsEdited;
            findDelete(id, target);
            dispatch(menuItemsDataEdit(itemsEdited));
            function findDelete(id, target) {
                for (let i = 0; i < target.length; i++) {
                    let element = target[i];
                    if (element.id === id) {
                        if (element.hasChild) {
                            const iterator = element.children.values();
                            for (const value of iterator) {
                                target.push(value);
                            }
                        }

                        target.splice(i, 1);
                        break;
                    } else if (element.hasChild) {
                        findDelete(id, element.children, menuId);
                    }
                }
                itemsEdited = target;
                return itemsEdited;
            }
        } catch (error) {
            if (error.data?.message) {
                showNotification(error.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
        }
    };
};

export const editMenuItem = (id, target, menuId, values) => {
    return async (dispatch, getState) => {
        try {
            let itemsEdited;
            findEdit(id, values, target);
            dispatch(menuItemsDataEdit(itemsEdited));

            function findEdit(id, values, target) {
                for (let i = 0; i < target.length; i++) {
                    const element = target[i];
                    if (element.id === id) {
                        element.name = values.name;
                        element.url = values.url;
                        element.new_tab = values.new_tab;
                        break;
                    } else if (element.hasChild) {
                        findEdit(id, values, element.children);
                    }
                }
                itemsEdited = target;
                return itemsEdited;
            }
        } catch (error) {
            if (error.data?.message) {
                showNotification(error.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
        }
    };
};

export const menusDataUpdate = (items, item) => {
    return (dispatch, getState) => {
        let prevParentId = item.parent_id;
        let parentId = '0';
        searchParentId(items);

        function searchParentId(arr) {
            arr.forEach((i) => {
                if (i.children) {
                    if (i.children.find((el) => el.id + '' === item.id + '')) {
                        parentId = i.id;
                    } else {
                        searchParentId(i.children);
                    }
                }
            });
        }
        let newItemsList = [...items];
        addHasChildProp(newItemsList);

        function addHasChildProp(arr) {
            arr.forEach((i) => {
                if (i.children) {
                    if (i.id + '' === parentId + '') {
                        i.hasChild = true;
                    }
                    if (i.id + '' === prevParentId + '') {
                        if (!i.children.length) {
                            i.hasChild = false;
                        }
                    }
                    addHasChildProp(i.children);
                }
            });
        }

        dispatch(menuItemsDataEdit([...newItemsList]));
    };
};

export const fetchLanguages = () => {
    return async (dispatch, getState) => {
        try {

            let data = await menusApi.fetchLanguagesList();

            dispatch(languagesListSet(data.items));

            const shouldUpdateDefaultLanguage = !getState().pages.selectedLanguage;

            if (shouldUpdateDefaultLanguage) {
                const default_item = data.items.find((item) => item.default_item === 1) || data.items[0];
                dispatch(selectedMenuItemLanguageSet(default_item));
            }
        } catch (error) {
            if (error.data?.message) {
                showNotification(error.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
        }
    };
};

export default menuItemsReducer;
