export const parseUrlWithLanguage = (url = '', availableLanguages) => {
    const urlSplitted = url.slice(1).split('/');

    const codes = [];
    for (let i = 0; i < availableLanguages.length; i++) {
        codes.push(availableLanguages[i].url_code);
    }

    if (codes.includes(urlSplitted[0])) {
        return { code: urlSplitted[0], pageUrl: urlSplitted.splice(1).join('/') };
    }
    return { code: '', pageUrl: urlSplitted.join('/') };
};
