import { itemsAPI } from '../api/api';
import { showNotification } from '../parts/Admin/utils/notifications/notifications';
import history from '../history';
import { getFormResults, setFormResults } from "./formResults-reducer"
import { setDataLoading } from './app-reducer';
import { fetchPagesData } from './pages-reducer';
import { fetchPageInfo } from './pageInfo-reducer';
import { getMenuItemData } from "./menu-items-reducer";

export const SET_ITEMS = `SET_ITEMS`;
export const SET_ITEM = 'SET_ITEM';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const CHANGE_SORT = 'CHANGE_SORT';
export const SET_FILTER_STRING = 'SET_FILTER_STRING';
export const SET_FILTER_OPEN = 'SET_FILTER_OPEN';
export const USERS_LIST_SET = 'USERS_LIST_SET';
export const SELECTED_USER_SET = 'SELECTED_USER_SET';
export const CHECKBOX_SETTER = 'CHECKBOX_SETTER';

function makeActionCreator(type, ...argNames) {

    return function (entity, ...args) {
        let action = { type: `${type}_${entity}` };

        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index];

        });
        return action;
    };
}

export const checkboxSet = makeActionCreator(CHECKBOX_SETTER, 'checkboxSetter');
export const setItems = makeActionCreator(SET_ITEMS, 'data');
export const setItem = makeActionCreator(SET_ITEM, 'data');
export const usersListSet = makeActionCreator(USERS_LIST_SET, 'data')
export const selectedUserSet = makeActionCreator(SELECTED_USER_SET, 'data');
export const setPageSize = makeActionCreator(SET_PAGE_SIZE, 'pageSize');
export const setTableBreadcrumbs = makeActionCreator(SET_BREADCRUMBS, 'breadcrumbs');
export const setFilterString = makeActionCreator(SET_FILTER_STRING, 'filterString');
export const setFilterOpen = makeActionCreator(SET_FILTER_OPEN, 'isFilterOpen');
export const setCurrentPage = makeActionCreator(SET_CURRENT_PAGE, 'currentPage');
export const changeSortActionCreator = makeActionCreator(
    CHANGE_SORT,
    'oldSortField',
    'oldSortType',
    'sortField',
);

export const successMessage = (data) => {
    if (Array.isArray(data.message)) {
        (data.message).forEach((value, i) => {
            showNotification(data.message[i], 'success', 'shifted');
        });
    }
    else {
        showNotification(data.message, 'success');
    }
}

export const errorMessage = (error) => {
    if (error.response?.status === 500) {
        showNotification('Server error', 'danger');
    } else {
        if (Array.isArray(error.response?.data.message)) {
            (error.response?.data.message).forEach((value, i) => {
                showNotification(error.response?.data.message[i], 'danger');
            });
        }
        else {

            showNotification(error.response?.data.message, 'danger');
        }
    }

}

export const updatePageSize = (entity, pageSize) => {
    return async (dispatch, getState) => {
        dispatch(setPageSize(entity, pageSize));
        dispatch(setCurrentPage(entity, 1));
    };
};

export const changeSort = (entity, oldSortField, oldSortType, sortField) => {
    return (dispatch) => {
        dispatch(changeSortActionCreator(entity, oldSortField, oldSortType, sortField));
        dispatch(getItems(entity.toLowerCase()));
    };
};

export const getItems = (entity, currentPage, pageSize) => {
    return async (dispatch, getState) => {
  
        dispatch(setDataLoading(false));
        if (entity === 'subscribergroups') {
            entity = 'subscriberGroups'
        } 
        if (entity === 'socialnetworks') {
            entity = 'socialNetworks'
        } 
        if (entity === 'media') {
            entity = 'sharedMedia'
        }
        if (entity === 'form-results') {
            entity = 'formResults'
        }
        if (entity === 'self-assessment') {
            entity = 'selfAssessment'
        }
        if (entity === 'drug-calculator') {
            entity = 'drugCalculator'
        }
        if (entity === 'self-assessment-result') {
            entity = 'selfAssessmentResult'
        }
        const sortField = getState()[entity].sortField;
        const sortType = getState()[entity].sortType;
        const filterString = getState()[entity].filterString;
        if (!currentPage) {
            currentPage = getState()[entity].currentPage;
        } else {
            // debugger
            let entityForCurrentPage = entity
            if (entity === 'formResults') {
                entityForCurrentPage = 'form-results'
            }
            if (entity === 'selfAssessment') {
                entityForCurrentPage = 'self-assessment'
            }
            if (entity === 'drugCalculator') {
                entityForCurrentPage = 'drug-calculator'
            }
            if (entity === 'selfAssessmentResult') {
                entityForCurrentPage = 'self-assessment-result'
            }
            dispatch(setCurrentPage(entityForCurrentPage.toUpperCase(), currentPage));
        }

        if (!pageSize) {
            pageSize = getState()[entity].pageSize;
        }

        try {
            let data = await itemsAPI.getItems(
                entity,
                pageSize,
                currentPage,
                sortField,
                sortType,
                filterString,
            );
            dispatch(setDataLoading(true));

            if (entity === 'formResults') {
                dispatch(setFormResults(data));
            }
            else if(entity === 'selfAssessment'){
                dispatch(setItems('self-assessment'.toUpperCase(), data));
            }
            else if(entity === 'drugCalculator'){
                dispatch(setItems('drug-calculator'.toUpperCase(), data));
            }
            else if(entity === 'selfAssessmentResult'){
                dispatch(setItems('self-assessment-result'.toUpperCase(), data));
            }
            else{
            dispatch(setItems(entity.toUpperCase(), data));
            }
        } catch (error) {
            errorMessage(error)
        } finally {
        }
    };
};

export const getItemData = (entity, id) => {
    return async (dispatch) => {
        try {
            let data = await itemsAPI.getItemData(entity, id);
            if(entity === 'selfAssessment'){
                dispatch(setItem('self-assessment'.toUpperCase(), data));
            }
            else if(entity === 'drugCalculator'){
                dispatch(setItem('drug-calculator'.toUpperCase(), data));
            }
            else{
                // debugger
            dispatch(setItem(entity.toUpperCase(), data));
            }
        } catch (error) {

            // errorMessage(error)
        } finally {
        }
    };
};

export const setDefault = (entity, id) => {
    return async (dispatch) => {
        try {
            let data = await itemsAPI.setDefault(entity, id);
            successMessage(data)
            dispatch(getItems(entity));
            dispatch(getItemData(entity, id));
            history.push(`/admiral-admin/${entity}`);
        } catch (error) {
            errorMessage(error)
        } finally {
        }
    };
};

export const setActive = (entity, routePartActive, id, pageId) => {
    return async (dispatch, getState) => {
        try {
            let data = await itemsAPI.setActive(entity, routePartActive, id);

            successMessage(data)


            if (entity === 'self-assessment-purple'){
                entity = 'self-assessment-result'
            }

            if (pageId) {
                dispatch(fetchPageInfo(pageId))
            } else {
                dispatch(getItems(entity));

                dispatch(getItemData(entity, id));
                if (entity === 'subscriberGroups') {
                    history.push(`/admiral-admin/subscribers/groups`);
                } else if (entity === 'socialNetworks') {
                    history.push(`/admiral-admin/social-networks`);
                }

            else {
                    history.push(`/admiral-admin/${entity}`);
                }
            }
        } catch (error) {

            errorMessage(error)
        } finally {
        }
    };
};


export const createItem = (entity, item, manualSchedules) => {
    console.log(item)
    return async (dispatch) => {
        try {
            let data = await itemsAPI.createItem(entity, item, manualSchedules);
            dispatch(getItems(entity));
            successMessage(data)
            if (entity === 'subscriberGroups') {
                history.push(`/admiral-admin/subscribers/groups`);
            } else if (entity === 'socialNetworks') {
                history.push(`/admiral-admin/social-networks`);
            } 
            else {
                history.push(`/admiral-admin/${entity}`);
            }
        } catch (error) {
            errorMessage(error)
        } finally {
        }
    };
};

export const deleteItem = (entity, id) => {
    return async (dispatch) => {
        try {
            let data = await itemsAPI.deleteItem(entity, id);
            successMessage(data)
            dispatch(getItems(entity));
            if (entity === 'subscriberGroups') {
                history.push(`/admiral-admin/subscribers/groups`);
            }
            else if (entity === 'socialNetworks') {
                history.push(`/admiral-admin/social-networks`);
            } 
            else {
                history.push(`/admiral-admin/${entity}`);
            }
        } catch (error) {
            errorMessage(error)
        } finally {
        }
    };
};

export const fetchUsers = (entity) => {
    return async (dispatch, getState) => {
        try {
            let data = await itemsAPI.getItems('users',
                '8',
                '1',
                'id',
                'desc',
                '',
            );
            dispatch(usersListSet(entity.toUpperCase(), data.items));

            // const shouldUpdateDefaultUser = !getState().themes.selectedUser;

            // if (shouldUpdateDefaultUser) {
            //     const default_item = data.items.find((item) => item.default_item === '1') || data.items[0];
            //     dispatch(selectedUserSet(entity.toUpperCase(),default_item));
            // }
        } catch (error) {
            errorMessage(error)
        } finally {
        }
    };
};

export const updateItem = (entity, { id, ...item }, apply, setName, setLanguage, setMenuPart) => {
    console.log(item)
    return async (dispatch) => {
        try {
            let data = await itemsAPI.updateItem(entity, { id, ...item });

            dispatch(getItems(entity));
            if (entity === 'menus'){
                dispatch(getMenuItemData(entity, id, setName, setLanguage, setMenuPart))
            }
            // dispatch(getItemData(entity,id));
            successMessage(data)
            if (entity === 'subscriberGroups') {
                history.push(`/admiral-admin/subscribers/groups`);
            }
            else if (entity === 'socialNetworks') {
                history.push(`/admiral-admin/social-networks`);
            }
            else if (!apply) {
                history.push(`/admiral-admin/${entity}`);
            }
        } catch (error) {
            errorMessage(error)
        } finally {
        }
    };
};

export const applyFilter = (entity, values) => {
    return (dispatch) => {
        let filterString = '';

        let fltStrProps = '&flt_by_props='
        let fltStrValues = '&flt_by_values='
        // debugger
        if (values) {
            if (values.active) {
                if (values.active.value === 2)
                    values.active = 1
                if (values.active.value === 3)
                    values.active = 0
            }
            if (values.default_item) {
                if (values.default_item.value === 2)
                    values.default_item = 1
                if (values.default_item.value === 3)
                    values.default_item = 0
            }
            if (values.create_user_id) {
                values.create_user_id = values.create_user_id.value
            }
            if (values.type && entity === "sharedMedia" && values.type.value  ) {

                values.type = values.type.value
                // debugger
            }
            if (values.update_user_id) {
                values.update_user_id = values.update_user_id.value
            }
            if (values.language_id) {
                values.language_id = values.language_id.value
            }
            let itemKeys = Object.keys(values);
            let itemValues = Object.values(values);

            for (let i = 0; i < itemKeys.length; i++) {
                if (i === itemKeys.length - 1) {
                    fltStrProps += `${itemKeys[i]}`
                    fltStrValues += `${itemValues[i]}`
                } else {
                    fltStrProps += `${itemKeys[i]},`
                    fltStrValues += `${itemValues[i]},`
                }
            }
            filterString += `${fltStrProps}${fltStrValues}&flt_date_operator=range`
        }

        dispatch(setFilterString(entity.toUpperCase(), filterString));
        dispatch(getItems(entity));

    };


};
