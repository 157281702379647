import Axios from 'axios';

export const siteUrl = 'https://api.harmreductioneurasia.org/';

const api = Axios.create({
    baseURL: siteUrl,
    withCredentials: true
});

export const userAPI = {
    async deleteInvitedUser(email) {
        const response = await api.post(`users/invited`, {
            email: email,
        });
        return response.data;
    },
    async changePassword(values) {
        const response = await api.patch(`users/changepass`, values);
        return response.data;
    },
    async inviteUser(values) {
        const response = await api.post(`users/invite`, values);
        return response.data;
    },
    async verify(hash) {

        const response = await api.get(`/auth/verify?hash=${hash}`);
        return response.data;
    },
    async loginInvite({ username, password, passwordValidation, phone, fio }) {
        const response = await api.post(`/users/signup`, {
            username,
            password,
            passwordValidation,
            phone,
            fio
        });
        return response.data;
    }
};

export const authAPI = {
    async restore(password, hash) {
        const response = await api.post(`auth/restore/${hash}`, { password });
        return response.data;
    },
    async getInvitedUsers() {
        const response = await api.get(`users/invited`);
        return response.data;
    },
    async getProfile() {
        const response = await api.get(`auth/profile`);
        return response.data;
    },
    async forgot({ email }) {
        const response = await api.post(`auth/forgot`, { email });
        return response.data;
    },
    async getPermissions() {
        const response = await api.get(`auth/permissions`);
        return response.data;
    },
    async login({ username, password }) {
        const response = await api.post(`/auth/login`, { username, password });
        return response.data;
    },
    async logout() {
        const response = await api.post(`auth/logout`);
        return response.data;
    }
};

export const formsAPI = {
    async updateForm(id, values, fields, submitButton) {
        const response = await api.patch(`forms/${id}`, {
            id: id,
            name: values.name,
            receiver: values.receiver,
            theme: values.theme,
            active: values.active,
            pu_header: values.pu_header,
            pu_text: values.pu_text,
            fields: fields,
            media_large: values.media_large.value,
            media_medium: values.media_medium.value,
            media_small: values.media_small.value,
            media_less_640: values.media_less_640.value,
            submit_button: submitButton
        });
        return response.data;
    },
    async createForm(values, fields, submitButton, cloneProps) {
        let payload = {
            name: values.name,
            receiver: values.receiver,
            theme: values.theme,
            // active: values.active,
            pu_header: values.pu_header,
            pu_text: values.pu_text,
            media_large: values.media_large.value ? values.media_large.value : values.media_large,
            media_medium: values.media_medium.value ? values.media_medium.value : values.media_medium,
            media_small: values.media_small.value ? values.media_small.value : values.media_small,
            media_less_640: values.media_less_640.value ? values.media_less_640.value : values.media_less_640,
            fields: fields,
            submit_button: submitButton || {}
        };


        if (cloneProps) {
            payload = Object.assign(payload, cloneProps);
        }

        const response = await api.post(`forms`, payload);
        return response.data;
    },
    async sendResult(data) {
        const response = await api.post(`/form-results`, data, { timeout: 6000 });
        return response.data;
    }
};

export const selfAssessmentAPI = {
    async sendResult(data) {
        const response = await api.post(`/self-assessment-result`, data, { timeout: 6000 });
        return response.data;
    },
    async sendResultHelp(data) {
        const response = await api.post(`self-assessment/help`, data, { timeout: 6000 });
        return response.data;
    },
    async sendResultPurple(data) {
        const response = await api.post(`self-assessment-purple`, data, { timeout: 6000 });
        return response.data;
    },
    async updateAssessment(entity, id, data, components) {
        const response = await api.patch(`${entity}/${id}`, {
            name: data.name,
            active: data.active,
            email:data.email,
            description: data.description ? data.description : '',
            media_large: data.media_large.value,
            media_medium: data.media_medium.value,
            media_small: data.media_small.value,
            media_less_640: data.media_less_640.value,
            items: components,
        });
        return response.data;
    },
};

export const itemsAPI = {
    async fetchMailingList() {
        const response = await api.get(`/mailing`);
        return response.data;
    },
    async instantMailing(id) {
        const response = await api.post(`/schedules/force/${id}`);
        return response.data;
    },
    async instantManualMailing(id) {
        const response = await api.post(`/schedules/manual-force/${id}`);
        return response.data;
    },
    async getItems(entity, pageSize, currentPage, sortField, sortType, filterString) {
        if (entity === 'subscriberGroups') {
            const response = await api.get(
                `subscribers/groups?per_page=${pageSize}&page_number=${currentPage}&sort_field=${sortField}&sort_type=${sortType}${filterString}`);
            return response.data;
        } else if (entity === 'socialNetworks') {
            const response = await api.get(
                `social-networks/?per_page=${pageSize}&page_number=${currentPage}&sort_field=${sortField}&sort_type=${sortType}${filterString}`);
            return response.data;
        } 
     else if (entity === 'formResults') {
        const response = await api.get(
            `form-results/?per_page=${pageSize}&page_number=${currentPage}&sort_field=${sortField}&sort_type=${sortType}${filterString}`);
        return response.data;
    } else if (entity === 'selfAssessment') {
            const response = await api.get(
              `self-assessment/?per_page=${pageSize}&page_number=${currentPage}&sort_field=${sortField}&sort_type=${sortType}${filterString}`);
            return response.data;
        }else if (entity === 'selfAssessmentResult') {
            const response = await api.get(
                `self-assessment-purple/?per_page=${pageSize}&page_number=${currentPage}&sort_field=${sortField}&sort_type=${sortType}${filterString}`);
            return response.data;
        }
     else if (entity === 'sharedMedia') {
            const response = await api.get(
                `shared-media?per_page=${pageSize}&page_number=${currentPage}&sort_field=${sortField}&sort_type=${sortType}${filterString}`);
            return response.data;
        }
    else if (entity === 'drugCalculator') {
    const response = await api.get(
        `drug-calculator/?per_page=${pageSize}&page_number=${currentPage}&sort_field=${sortField}&sort_type=${sortType}${filterString}`);
    return response.data;
}
else {
            const response = await api.get(
                `${entity}?per_page=${pageSize}&page_number=${currentPage}&sort_field=${sortField}&sort_type=${sortType}${filterString}`
            );
            return response.data;
        }
    },
    
    async getAssessments() {
        const response = await api.get(`/self-assessment?flt_by_props=active&flt_by_values=1`);
        return response.data;
    },
    async getQuizzesStatistics() {
        const response = await api.get(`/quizz-entries/stats/total`);
        return response.data;
    },

    async getPagesStatistics(limit, days) {
        const response = await api.get(`/dashboard/top/${limit}/${days}`);
        return response.data;
    },

    async specificQuizStatistics(id) {
        const response = await api.get(`/quizz-entries/stats/histogram/${id}`);
        return response.data;
    },

    async specificPageStatistics(id, days) {
        const response = await api.get(`/dashboard/page/${id}/${days}`);
        return response.data;
    },
    async getFormResults(pageSize, currentPage, sortField, sortType, filterString) {
        const response = await api.get(`form-results?per_page=${pageSize}&page_number=${currentPage}&sort_field=${sortField}&sort_type=${sortType}${filterString}`);
        return response.data;
    },
    async getDrugResults(pageSize, currentPage, sortField, sortType, filterString) {
        const response = await api.get(`drug-calculator?per_page=${pageSize}&page_number=${currentPage}&sort_field=${sortField}&sort_type=${sortType}${filterString}`);
        return response.data;
    },
    async getData(id) {
        const response = await api.get(`form-results/get/${id}`);
        return response.data;
    },
    async getForms(id) {
        const response = await api.get(`forms`);
        return response.data;
    },
    async getItemData(entity, id) {
        if (entity === 'subscriberGroups') {
            const response = await api.get(`subscribers/groups/get/${id}`);
            return response.data;
        } else if (entity === 'socialNetworks') {
            const response = await api.get(`social-networks/${id}`);
            return response.data;
        } else if (entity === 'self-assessment-result') {
            const response = await api.get(`self-assessment-purple/get/${id}`);
            return response.data;
        } else {
            // debugger
            const response = await api.get(`${entity}/get/${id}`);
            return response.data;
        }
    },
    async setDefault(entity, id) {
        const response = await api.patch(`${entity}/set-default/${id}`);
        return response.data;
    },
    async setActive(entity, routePartActive, id) {
        if (entity === 'subscriberGroups') {
            const response = await api.patch(`subscribers/groups/${routePartActive}/${id}`);
            return response.data;
        } else if (entity === 'socialNetworks') {
            const response = await api.patch(`social-networks/${routePartActive}/${id}`);
            return response.data;
        } else {
            const response = await api.patch(`${entity}/${routePartActive}/${id}`);
            return response.data;
        }
    },
    async createItem(entity, item, manualSchedules) {
        if (entity === 'subscriberGroups') {
            const response = await api.post(`subscribers/groups`, item);
            return response.data;
        }
        else if (entity === 'subscribers') {
            const response = await api.post(`subscribers/create`, item);
            return response.data;
        }
        else if (entity === 'socialNetworks') {
            const response = await api.post(`social-networks`, item);
            return response.data;
        }
        else if (manualSchedules) {
            const response = await api.post(`schedules/manual-force`, item);
            return response.data;
        } else {
            const response = await api.post(`${entity}`, item);
            return response.data;
        }
    },
    async deleteItem(entity, id) {
        if (entity === 'subscriberGroups') {
            const response = await api.delete(`subscribers/groups/${id}`);
            return response.data;
        } else if (entity === 'socialNetworks') {
            const response = await api.delete(`social-networks/${id}`);
            return response.data;
        } else if (entity === 'self-assessment-result') {
            const response = await api.delete(`self-assessment-purple/${id}`);
            return response.data;
        } else {
            const response = await api.delete(`${entity}/${id}`);
            return response.data;
        }
    },
    async deleteFormResults(id) {
        const response = await api.delete(`form-results/${id}`);
        return response.data;
    },
    async updateItem(entity, { id, ...item })  {
        if (entity === 'users') {
            const response = await api.patch(`${entity}/patch/${id}`, item);
            return response.data;
        } else if (entity === 'subscriberGroups') {
            const response = await api.patch(`subscribers/groups/patch/${id}`, item);
            return response.data;
        } else if (entity === 'socialNetworks') {
            const response = await api.patch(`social-networks/${id}`, item);
            return response.data;
        } else if (entity === 'self-assessment-result') {
        const response = await api.patch(`self-assessment-purple/${id}`, item);
        return response.data;
        }
        else {
            const response = await api.patch(`${entity}/${id}`, item);
            return response.data;
        }
    },
    async updateUser(entity, id, formData) {
        const response = await api.patch(`${entity}/patch/${id}`, formData);
        return response.data;
    },
    async updateSelfAssessmentResult(id, formData) {
        const response = await api.patch(`self-assessment-purple/${id}`, formData);
        return response.data;
    },
    async updateSocialNetwork(entity, id, formData) {
        const response = await api.patch(`social-networks/${id}`, formData);
            return response.data;
    },
    
    async updateQuiz(entity, id, data, components) {
        const response = await api.patch(`${entity}/${id}`, {
            name: data.name,
            continue_button_text: data.continue_button_text,
            finish_button_text: data.finish_button_text,
            text_after_finish: data.text_after_finish,
            instantly_show: data.instantly_show,
            description: data.description,
            active: data.active,
            media_large: data.media_large.value,
            media_medium: data.media_medium.value,
            media_small: data.media_small.value,
            media_less_640: data.media_less_640.value,
            items: components,
        });
        return response.data;
    },
    async createQuiz(entity, values, components, cloneProps) {
        let payload = {
            name: values.name,
            continue_button_text: values.continue_button_text,
            finish_button_text: values.finish_button_text,
            text_after_finish: values.text_after_finish,
            instantly_show: values.instantly_show,
            description: values.description,
            media_large: values.media_large.value ? values.media_large.value : values.media_large,
            media_medium: values.media_medium.value ? values.media_medium.value : values.media_medium,
            media_small: values.media_small.value ? values.media_small.value : values.media_small,
            media_less_640: values.media_less_640.value ? values.media_less_640.value : values.media_less_640,
            items: components,
        };
        if (cloneProps) {
            payload = Object.assign(payload, cloneProps);
        }

        const response = await api.post(`${entity}`, payload);
        return response.data;
    },
    async updateMap(entity, id, data, components) {
        const response = await api.patch(`${entity}/${id}`, {
            name: data.name,
            active: data.active,
            media_large: data.media_large.value,
            media_medium: data.media_medium.value,
            media_small: data.media_small.value,
            media_less_640: data.media_less_640.value,
            items: components,
        });
        return response.data;
    },
    async updateDrugCalculator(entity, id, data, components) {
        const response = await api.patch(`${entity}/${id}`, {
            name: data.name,
            active: data.active,
            media_large: data.media_large.value,
            media_medium: data.media_medium.value,
            media_small: data.media_small.value,
            media_less_640: data.media_less_640.value,
            items: components,
        });
        return response.data;
    },
    async createMap(entity, values, components, cloneProps) {
        let payload = {
            name: values.name,
            media_large: values.media_large.value ? values.media_large.value : values.media_large,
            media_medium: values.media_medium.value ? values.media_medium.value : values.media_medium,
            media_small: values.media_small.value ? values.media_small.value : values.media_small,
            media_less_640: values.media_less_640.value ? values.media_less_640.value : values.media_less_640,
            items: components,
        };
        if (cloneProps) {
            payload = Object.assign(payload, cloneProps);
        }
        const response = await api.post(`${entity}`, payload);
        return response.data;
    },
    async createAssessment(entity, values, components, cloneProps) {
        let payload = {
            name: values.name,
            email: values.email,
            media_large: values.media_large.value ? values.media_large.value : values.media_large,
            media_medium: values.media_medium.value ? values.media_medium.value : values.media_medium,
            media_small: values.media_small.value ? values.media_small.value : values.media_small,
            media_less_640: values.media_less_640.value ? values.media_less_640.value : values.media_less_640,
            description: values.description ? values.description : '',
            items: components,
        };
        if (cloneProps) {
            payload = Object.assign(payload, cloneProps);
        }
        const response = await api.post(`${entity}`, payload);
        return response.data;
    },
    async createDrugCalculator(entity, values, components, cloneProps) {
        let payload = {
            name: values.name,
            media_large: values.media_large.value ? values.media_large.value : values.media_large,
            media_medium: values.media_medium.value ? values.media_medium.value : values.media_medium,
            media_small: values.media_small.value ? values.media_small.value : values.media_small,
            media_less_640: values.media_less_640.value ? values.media_less_640.value : values.media_less_640,
            items: components,
        };
        if (cloneProps) {
            payload = Object.assign(payload, cloneProps);
        }
        const response = await api.post(`${entity}`, payload);
        return response.data;
    },
    async updateItemThemes(entity, { name, id, ...themeParams }) {
        const response = await api.patch(`${entity}/${id}`, { name, content: themeParams });
        return response.data;
    },

    async itemDataUpdate(entity, item, parentId) {
        const response = await api.patch(`${entity}/utilize/${item.id}`, { parent_id: parentId });
        return response.data;
    },
    async updateField(entity, { id, ...fieldForm }) {
        const response = await api.patch(`${entity}/utilize/${id}`, fieldForm);
        return response.data;
    }
};

export const pagesTableAPI = {
    async setActive(entity, id) {
        const response = await api.patch(`${entity}/activate/${id}`);
        return response.data;
    },
    async deleteItem(entity, id) {
        const response = await api.delete(`${entity}/${id}`);
        return response.data;
    },
    async createItem(entity, { name }) {
        const response = await api.post(`${entity}`, { name });
        return response.data;
    },
    async updateItem(entity, { name, id }) {
        const response = await api.patch(`${entity}/${id}`, { name });
        return response.data;
    }
};

export const pageInfoAPI = {
    async fetchPageInfo(id) {
        const response = await api.get(`/pages/get/${id}`);
        return response.data;
    },
    async fetchPagesList(language_id) {
        const response = await api.get(`/pages?flt_by_values=${language_id}&flt_by_props=language_id`);
        return response.data;
    },
    async fetchChildrenList(parent) {
        const response = await api.get(`/pages?flt_by_props=parent_id,active&flt_by_values=${parent},1`);
        return response.data;
    },
    async fetchAllPagesList() {
        const response = await api.get(`/pages`);
        return response.data;
    },
    async fetchLanguagesList() {
        const response = await api.get(`/languages`);
        return response.data;
    },
    async fetchGroupsList() {
        const response = await api.get(`/subscribers/groups`);
        return response.data;
    },
    async fetchExistingModules() {
        const response = await api.get(`/modules/unique`);
        return response.data;
    },
    async fetchExistingForms() {
        const response = await api.get(`/forms/unique`);
        return response.data;
    },
    async fetchExistingMaps() {
        const response = await api.get(`/maps/unique`);
        return response.data;
    },
    async fetchExistingQuizzes() {
        const response = await api.get(`/quizzes/unique`);
        return response.data;
    },
    async fetchExistingAssessments() {
        const response = await api.get(`/self-assessment/unique`);
        return response.data;
    },
    async fetchExistingCalculators() {
        const response = await api.get(`/drug-calculator/unique`);
        return response.data;
    },
    async updatePage(id, formData) {
        const response = await api.patch(`/pages/${id}`, formData);
        return response.data;
    },
    async updatePageModules(id, moduleIdsArray) {
        const response = await api.patch(`/pages/${id}`, {
            modules: moduleIdsArray
        });
        return response.data;
    },
    async createPage(formData) {
        const response = await api.post(`/pages`, formData);
        return response.data;
    },
    async duplicatePage(id) {
        const response = await api.post(`/pages/copy/${id}`)
        return response.data;
    },
    async groupPage(id, language_group) {
        const response = await api.patch(`/pages/${id}`, { language_group });
        return response.data;
    },
    async deletePage(id) {
        const response = await api.delete(`/pages/${id}`);
        return response.data;
    },
    async fetchPages() {
        const response = await api.get('/pages');
        return response.data;
    }
};

export const pagesAPI = {
    async pagesDataUpdate(item, parentId) {
        const response = await api.patch(`pages/${item.id}`, { parent_id: parentId });
        return response.data;
    },
    async fetchPagesData(id, parentId, pageSize, currentPage, sortField, sortType, filterString) {
        const response = await api.get(
            `pages?flt_by_values=${id},${parentId}&per_page=${pageSize}&page_number=${currentPage}&sort_field=${sortField}&sort_type=${sortType}&flt_by_props=language_id,parent_id`
            // `&per_page=${pageSize}&page_number=${currentPage}&sort_field=${sortField}&sort_type=${sortType}${filterString}`

        );
        return response.data;
    },
    async getPagesData(id, parentId, pageSize, currentPage, sortField, sortType, filterString) {
        const response = await api.get(
            `pages?&per_page=${pageSize}&page_number=${currentPage}&sort_field=${sortField}&sort_type=${sortType}${filterString}`
            // `&per_page=${pageSize}&page_number=${currentPage}&sort_field=${sortField}&sort_type=${sortType}${filterString}`

        );
        return response.data;
    }
};

export const publicAPI = {
    async postSession(agent, session, duration, page_id) {
        const response = await api.post(`/views/create`,
            {
                agent: agent,
                session: session,
                duration: duration,
                page_id: page_id
            });
        return response.data;
    },
    async fetchSocials() {
        const response = await api.get(`/social-networks?flt_by_values=1&flt_by_props=active`);
        return response.data;
    },
    async fetchPagesList(language_id) {
        const response = await api.get(`/pages?flt_by_values=${language_id},1&flt_by_props=language_id,active`);
        return response.data;
    },
    async fetchPagesData(id, parentId) {
        const response = await api.get(
            `pages?flt_by_values=${id},${parentId},1&flt_by_props=language_id,parent_id,active`
        );
        return response.data;
    },
    async fetchQuizzesList() {
        const response = await api.get(`/quizzes?flt_by_values=1&flt_by_props=active`);
        return response.data;
    },
    async fetchGroupsList() {
        const response = await api.get(`/subscribers/groups?flt_by_values=false,1&flt_by_props=invite_only,active`);
        return response.data;
    },
    async manageSubscriber({ id, ...item }) {
        const response = await api.patch(`/subscribers/manage/${id}`, item);
        return response.data;
    },
    async subscribe(email, groups_id) {
        const response = await api.post(`/subscribers/create`, {
            email: email,
            groups_id: groups_id,
        });
        return response.data;
    },
    async sendQuizResults(id, results) {
        const response = await api.post(`/quizz-entries`, {
            answers: results,
            quizz_id: id
        })
        return response.data;
    },
    async getActiveTheme() {
        const response = await api.get(`/themes/get-active`);
        return response.data;
    },
    async getLanguage() {
        const response = await api.get(`/languages/get-default`);
        return response.data;
    },
    async getLanguages(pageSize, currentPage, sortField, sortType, filterString) {
        const response = await api.get(`/languages?per_page=${pageSize}&page_number=${currentPage}&sort_field=${sortField}&sort_type=${sortType}${filterString}&flt_by_props=active&flt_by_values=1`);
        return response.data;
    },
    async getPageByUrl(url, langId) {
        const response = await api.get(`/pages/view-by-url?url=${url}&lang=${langId}`);
        return response.data;
    },
    async getPageGroupById(id, langId) {
        const response = await api.get(`/pages/language-group/${id}/${langId}`);
        return response.data;
    },
    async getMenu(langId) {
        const response = await api.get(`/menus?flt_by_props=language_id,active&flt_by_values=${langId},1`);
        return response.data;
    },
    async getPagesByParent(parent, sort, year) {
        const response = await api.get(`/pages?flt_by_props=parent_id,active${year !== null ? ',create_date' : ''}&flt_by_values=${parent},1${year !== null ? `,${year}-1-1to${year}-12-31&flt_date_operator=range` : ''}&sort_field=${sort ? sort : 'total_views'}&sort_type=desc`);
        return response.data;
    },
    async getPageById(id) {
        const response = await api.get(`/pages?flt_by_props=id,active&flt_by_values=${id},1`);
        return response.data;
    },
    async getPagesYears(id) {
        const response = await api.get(`/pages/years/${id}`);
        return response.data;
    },
    async getSearchPages(pageName, language_id, pagesValue) {
        const response = await api.get(`/pages/search?flt_by_values=${pageName},${language_id},1${pagesValue ? `&per_page=${pagesValue}` : ''}&flt_by_props=name,language_id,active`);
        return response.data;
    },
    async getPagesByIds(ids) {
        const response = await api.get(`/pages?ids=${ids}`);
        return response.data;
    },
};

export const siteSettingsAPI = {
    async getSiteSettings() {
        const response = await api.get('/global-settings',);
        return response.data;
    },
    async updateSiteSettings(data) {
        const response = await api.patch(`global-settings/upload`, data, { timeout: 6000 });
        return response.data;
    }
};

export const subscriptionSettingsAPI = {
    async getSubscriptionSettings() {
        const response = await api.get('/subscription-settings',);
        return response.data;
    },
    async updateSubscriptionSettings(data) {
        const response = await api.patch(`subscription-settings/upload`, data, { timeout: 6000 });
        return response.data;
    }
};

export const modulesAPI = {
    // async getItems(pageId) {
    //     const response = await apiPHP.get(`modules/get-by-page/${pageId}`);
    //     return response.data;
    // },
    async create(newModule) {
        const response = await api.post(`/modules`, newModule);
        return response.data;
    },
    async update({ name, type, content, page_id, moduleId }) {
        const response = await api.patch(`/modules/${moduleId}`, {
            name: name,
            content: content
        });
        return response.data;
    },
    async delete(moduleId) {
        const response = await api.delete(`/modules/${moduleId}`);
        return response.data;
    }
};

export const mediaApi = {
    async getMedia() {
        const response = await api.get('/shared-media');
        return response.data;
    },
    async postMedia(data) {
        const response = await api.post('/shared-media/upload', data, { timeout: 8000 });
        return response.data;
    },
    async updateMedia(id, data) {
        const response = await api.patch(`/shared-media/update/${id}`, data);
        return response.data;
    },
    async deleteMedia(id) {
        const response = await api.delete(`/shared-media/delete/${id}`);
        return response.data;
    },
    async getExtensions() {
        const response = await api.get('/shared-media/extentions');
        return response.data;
    }
};

export const menusApi = {
    async getPages() {
        const response = await api.get('/pages/tree');
        return response.data;
    },
    async fetchLanguagesList() {
        const response = await api.get(`/languages`);
        return response.data;
    }
};

export const supportForm = {
    async sendSupportForm(email, name, subject, question) {
        const response = await api.post(`/support`, {
            email: email,
            message: question,
            subject: subject,
            name: name
        })
        return response.data;
    }
};

