import { toast } from 'react-toastify';
import { publicAPI, pageInfoAPI, supportForm, pagesAPI } from '../api/api';
import history from '../history';
import { showNotification } from '../parts/Admin/utils/notifications/notifications';
import { parseUrlWithLanguage } from '../parts/Public/utils/url';
import { errorMessage, successMessage } from './actions';
import { fetchPagesList } from './pageInfo-reducer';

const SET_ACTIVE_THEME = 'SET_ACTIVE_THEME';
const SET_PAGE_DATA = 'SET_PAGE_DATA';
const SET_RELATED_PAGE = 'SET_RELATED_PAGE';
const SET_NESTED_PAGES_NEWS = 'SET_NESTED_PAGES_NEWS';
const SET_NESTED_PAGES_EVENTS = 'SET_NESTED_PAGES_EVENTS';
const SET_LANGUAGE = 'SET_LANGUAGE';
const SET_DEFAULT_LANGUAGE = 'SET_DEFAULT_LANGUAGE';
const SET_ACTIVE_LANGUAGES = 'SET_ACTIVE_LANGUAGES';
const SET_MENU = 'SET_MENU';
const TOGGLE_DATA_LOADING = 'TOGGLE_DATA_LOADING';
const SET_USER_DATA = 'SET_USER_DATA';
const TOGGLE_LOADING_LINE = 'TOGGLE_LOADING_LINE';
const TOGGLE_SUBSCRIBE = 'TOGGLE_SUBSCRIBE';
const SET_LOADING = 'SET_LOADING';
const GROUPS_LIST_SET = 'GROUPS_LIST_SET';
const GROUPS_LIST_LOADING = 'GROUPS_LIST_LOADING';
const SET_IS_HALF = 'SET_IS_HALF';
const SET_DRUG = 'SET_DRUG'
const SET_DRUG_KIND = 'SET_DRUG_KIND'
const SET_PAGES_DATA = 'SET_PAGES_DATA';
const ACTIVE_PAGE_ID = 'ACTIVE_PAGE_ID';
const PARENT_LOADING_ID = 'PARENT_LOADING_ID';
const ALL_PAGES_LIST_SET = 'ALL_PAGES_LIST_SET';
const SET_QUIZZES = 'SET_QUIZZES';
const SET_SOCIALS = 'SET_SOCIALS';
const SET_PAGES_YEARS = 'SET_PAGES_YEARS';
const SET_COMPONENTS = 'SET_COMPONENTS';
const SEARCH_PAGES = 'SEARCH_PAGES';
const PAGES_BY_IDS = 'PAGES_BY_IDS';
const FILTERS_PAGES_BY_IDS = 'FILTERS_PAGES_BY_IDS';

const initialState = {
    isDataReady: true,
    activeThemeId: null,
    activeThemeName: null,
    activeThemeContent: null,
    activeLanguage: null,
    defaultLanguage: null,
    parentLoadingId: null,
    menu: null,
    pageData: {
        template: null
    },
    components: [],
    availableLanguages: [],
    nestedPagesNews: {},
    nestedPagesEvents: [],
    profile: null,
    relatedPage: null,
    totalCount: null,
    loadingLine: false,
    toggleSubscribe: false,
    isLoading: false,
    groupsList: [],
    groupsListLoading: false,
    isHalf: false,
    drug: null,
    drugKind: null,
    pagesData: [],
    activePageId: null,
    allPagesList: null,
    existingQuizzes: [],
    socials: [],
    pagesYears: null,
    searchedPages: null,
    pagesByIds: null,
    filtersPagesByIds: null,
};

const publicReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_THEME:
            return {
                ...state,
                activeThemeId: action.id,
                activeThemeName: action.name,
                activeThemeContent: action.content
            };
        case SET_PAGE_DATA:
            return {
                ...state,
                pageData: action.payload,
                components: action.payload?.items || []

            };
        case SET_COMPONENTS:
            return {
                ...state,
                components: action.payload
            }
        case ALL_PAGES_LIST_SET:
            return {
                ...state,
                allPagesList: action.payload
            };
        case SET_PAGES_DATA:
            return {
                ...state,
                pagesData: action.payload,
            };
        case SET_IS_HALF:
            return {
                ...state,
                isHalf: action.payload,
            };
        case SET_DRUG:
            return {
                ...state,
                drug: action.payload,
            };
        case SET_QUIZZES:
            return {
                ...state,
                existingQuizzes: action.payload
            };
        case SET_SOCIALS:
            return {
                ...state,
                socials: action.payload
            };
        case SET_DRUG_KIND:
            return {
                ...state,
                drugKind: action.payload,
            };
        case SET_RELATED_PAGE:
            return {
                ...state,
                relatedPage: action.payload || [],
            };
        case SET_PAGES_YEARS:
            return {
                ...state,
                pagesYears: action.payload || [],
            };
        case SET_NESTED_PAGES_NEWS:
            const obj = { ...state.nestedPagesNews }
            obj[action.parent] = action.payload
            return {
                ...state,
                nestedPagesNews: obj,
            };
        case SET_NESTED_PAGES_EVENTS:
            return {
                ...state,
                nestedPagesEvents: action.payload,
            };
        case GROUPS_LIST_SET:
            return {
                ...state,
                groupsList: action.payload
            };
        case GROUPS_LIST_LOADING:
            return {
                ...state,
                groupsListLoading: action.payload
            }
        case SET_LANGUAGE:
            return {
                ...state,
                activeLanguage: action.payload
            };
        case SET_DEFAULT_LANGUAGE:
            return {
                ...state,
                defaultLanguage: action.payload
            };
        case SET_ACTIVE_LANGUAGES:
            return {
                ...state,
                availableLanguages: action.payload
            };
        case SET_MENU:
            return {
                ...state,
                menu: action.payload
            };
        case TOGGLE_DATA_LOADING:
            return {
                ...state,
                isDataReady: action.payload
            };
        case SET_USER_DATA:
            return {
                ...state,
                profile: action.payload
            };
        case TOGGLE_LOADING_LINE:
            return {
                ...state,
                loadingLine: action.payload
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        case PARENT_LOADING_ID:
            return {
                ...state,
                parentLoadingId: action.data
            };
        case TOGGLE_SUBSCRIBE:
            return {
                ...state,
                toggleSubscribe: action.payload
            };
        case ACTIVE_PAGE_ID:
            return {
                ...state,
                activePageId: action.payload
            };
        case SEARCH_PAGES:
            return {
                ...state,
                searchedPages: action.payload
            };
        case PAGES_BY_IDS:
            return {
                ...state,
                pagesByIds: action.payload
            };
        case FILTERS_PAGES_BY_IDS:
            return {
                ...state,
                filtersPagesByIds: action.payload
            };
        default:
            return state;
    }
};

const allPagesListSet = (payload) => ({
    type: ALL_PAGES_LIST_SET,
    payload
});

const setActiveTheme = (id, name, content) => ({
    type: SET_ACTIVE_THEME,
    id,
    name,
    content
});

export const setHalf = (payload) => ({
    type: SET_IS_HALF,
    payload
});

export const setQuizzesList = (quizzes) => ({
    type: SET_QUIZZES,
    payload: quizzes
});

export const setSocials = (socials) => ({
    type: SET_SOCIALS,
    payload: socials
});

export const setDrug = (payload) => ({
    type: SET_DRUG,
    payload
});

export const setDrugKind = (payload) => ({
    type: SET_DRUG_KIND,
    payload
});

const setActiveLanguage = (payload) => ({
    type: SET_LANGUAGE,
    payload
});

export const setActivePageId = (payload) => ({
    type: ACTIVE_PAGE_ID,
    payload
});

const setDefaultLanguage = (payload) => ({
    type: SET_DEFAULT_LANGUAGE,
    payload
});

const groupsListSet = (payload) => ({
    type: GROUPS_LIST_SET,
    payload
})

const setMenu = (payload) => ({
    type: SET_MENU,
    payload
});

const setPageData = (payload) => ({
    type: SET_PAGE_DATA,
    payload
});

const parentLoadingId = (payload) => ({
    type: PARENT_LOADING_ID,
    payload
});

const setPagesData = (payload) => ({
    type: SET_PAGES_DATA,
    payload
});

const setRelatedPage = (payload) => ({
    type: SET_RELATED_PAGE,
    payload
});

const setPagesYears = (payload) => ({
    type: SET_PAGES_YEARS,
    payload
});

const setNestedPagesNews = (parent, payload) => ({
    type: SET_NESTED_PAGES_NEWS,
    parent,
    payload
});

const setNestedPagesEvents = (payload) => ({
    type: SET_NESTED_PAGES_EVENTS,
    payload
});

export const setComponents = (data) => ({
    type: SET_COMPONENTS,
    payload: data
});

const setActiveLanguages = (payload) => ({
    type: SET_ACTIVE_LANGUAGES,
    payload
});

export const toggleLoading = (payload) => ({
    type: TOGGLE_DATA_LOADING,
    payload
});

export const groupsListLoading = (payload) => ({
    type: GROUPS_LIST_LOADING,
    payload
});

export const setLoading = (payload) => ({
    type: SET_LOADING,
    payload
});

export const toggleSubcription = (payload) => ({
    type: TOGGLE_SUBSCRIBE,
    payload: payload,
})

export const getActiveTheme = () => {
    return async (dispatch) => {
        try {
            let data = await publicAPI.getActiveTheme();

            dispatch(setActiveTheme(data.id, data.name, data.content));
        } catch (error) {
            console.log(error);
        }
    };
};

export const getActiveLanguage = () => {
    return async (dispatch) => {
        try {
            let defaultLanguage = await publicAPI.getLanguage();

            dispatch(setActiveLanguage(defaultLanguage));
            dispatch(setDefaultLanguage(defaultLanguage));
        } catch (error) {
            console.log(error);
        }
    };
};

export const saveSettings = (id, pageData, url) => {
    return async (dispatch, getState) => {
        try {
            const items = getState().public.components;

            const content = {
                modules: {},
                forms: {},
                maps: {},
                quizzes: {}
            };

            items.forEach((item, index) => {
                // TODO: replace with object field
                const type = item.group_type;
                content[type][item.id] = index;
            });
            pageData.append('content', JSON.stringify(content));
            let data = await pageInfoAPI.updatePage(id, pageData);
            dispatch(getPageByUrl(url))
            showNotification('You have successfully saved', 'success');
        } catch (error) {
            errorMessage(error)
            console.log(error);
        }
    };
};

export const getMenu = () => {
    return async (dispatch, getState) => {

        const defaultLanguage = getState().public.defaultLanguage;
        const activeLanguage = getState().public.activeLanguage;
        try {
            // temp method
            const data = await publicAPI.getMenu(activeLanguage.id);
            dispatch(setMenu(data));
            
        } catch (error) {
            console.log(error);
        }
    };
};


export const fetchExistingQuizzes = () => {
    return async (dispatch) => {
        try {
            const response = await pageInfoAPI.fetchExistingQuizzes();
            dispatch(setQuizzesList(response));
        } catch (error) {
            console.log(error);
        }
    };
};

export const fetchSocials = () => {
    return async (dispatch) => {
        try {
            const response = await publicAPI.fetchSocials();
            dispatch(setSocials(response.items));
        } catch (error) {
            console.log(error);
        }
    };
};

export const getPagesByParentNews = (parent, sort, year) => {
    return async (dispatch) => {
        try {
            // temp method
            const data = await publicAPI.getPagesByParent(parent, sort, year);

            // data.items.sort(function(a, b) {
            //     let dateA = new Date(a.create_date), dateB = new Date(b.create_date);
            //     return dateA - dateB;
            // });
            dispatch(setNestedPagesNews(parent, data))
        } catch (error) {
            console.log(error);
        }
    };
};

export const getPagesByParentEvents = (parent, sort, year) => {
    return async (dispatch) => {
        try {
            // temp method
            const data = await publicAPI.getPagesByParent(parent, sort, year);
            //    data.items.sort(function(a, b) {
            //         let dateA = new Date(a.create_date), dateB = new Date(b.create_date);
            //         return dateB - dateA;
            //     });
            dispatch(setNestedPagesEvents(data));
        } catch (error) {
            console.log(error);
        }
    };
};

export const getRelatedPageById = (id) => {
    return async (dispatch) => {
        try {

            // temp method
            const data = await publicAPI.getPageById(id);

            dispatch(setRelatedPage(data?.items[0] ? data?.items[0] : null));
        } catch (error) {
            console.log(error);
        }
    };
};

export const getPagesYears = (id) => {
    return async (dispatch) => {
        try {
            const data = await publicAPI.getPagesYears(id);
            dispatch(setPagesYears(data.items));
        } catch (error) {
            console.log(error);
        }
    };
};

export const getPublicData = () => {
    return async (dispatch) => {
        try {
            dispatch(toggleLoading(false));
            const promise1 = dispatch(getActiveLanguage());
            const promise2 = dispatch(getActiveTheme());
            const promise3 = dispatch(getMenu());
            const promise4 = dispatch(getAvailableLanguages());
            const promise5 = dispatch(fetchSocials());

            await Promise.all([promise1, promise2, promise3, promise4, promise5]);
            dispatch(toggleLoading(true));
        } catch (error) {
            console.log(error);
        }
    };
};


export const getAllPagesList = (language_id) => {
    return async (dispatch, getState) => {
        try {
            let data = await publicAPI.fetchPagesList(language_id);
            dispatch(allPagesListSet(data.items));
        } catch (error) {
            (error.response?.data.message).forEach((value, i) => {
                showNotification(error.response?.data.message[i], 'danger');
            });
        } finally {
        }
    };
};

export const postSession = (agent, session, duration, page_id) => {
    return async (dispatch) => {
        try {
            let data = await publicAPI.postSession(agent, session, duration, page_id);
            // dispatch(getItems(entity));
            // successMessage(data)
            // history.push(`/admiral-admin/${entity}`);
        } catch (error) {
            console.log(error);
            // errorMessage(error)
        } finally {
        }
    };
};

export const fetchGroupsList = () => {
    return async (dispatch) => {
        try {
            dispatch(groupsListLoading(true));
            let data = await publicAPI.fetchGroupsList();
            dispatch(groupsListSet(data.items));
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(groupsListLoading(false));
        }
    };
};


export const manageSubscriber = ({ id, ...item }) => {
    return async (dispatch) => {
        try {
            let data = await publicAPI.manageSubscriber({ id, ...item });
            successMessage(data)
        } catch (error) {
            errorMessage(error)
        } finally {
        }
    };
};

export const pagesDataUpdate = (items, item) => {
    return async (dispatch, getState) => {
        try {
            let prevParentId = item.parent_id;
            let parentId = null;
            searchParentId(items);
            function searchParentId(arr) {
                arr.forEach((i) => {
                    if (i.children) {
                        if (i.children.find((el) => el.id + '' === item.id + '')) {
                            parentId = i.id;
                        } else {
                            searchParentId(i.children);
                        }
                    }
                });
            }
            await pagesAPI.pagesDataUpdate(item, parentId);
            let newItemsList = [...items];
            addHasChildProp(newItemsList);
            function addHasChildProp(arr) {
                arr.forEach((i) => {
                    if (i.children) {
                        if (i.id + '' === parentId + '') {
                            i.hasChild = true;
                        }
                        if (i.id + '' === prevParentId + '') {
                            if (!i.children.length) {
                                i.hasChild = false;
                            }
                        }
                        addHasChildProp(i.children);
                    }
                });
            }
            dispatch(setPagesData([...newItemsList]));
            dispatch(fetchPagesData(parentId));
        } catch (error) {
            console.log(error);
        } finally {
        }
    };
};

export const fetchPagesData = (parentId = null, currentPage, pageSize) => {
    return async (dispatch, getState) => {
        // dispatch(setDataLoading(false));

        try {
            dispatch(parentLoadingId(parentId));
            if (!getState().public?.activeLanguage) return;
            // dispatch(pagesDataLoading(true));
            // const sortField = getState().pagesTable.sortField;
            // const sortType = getState().pagesTable.sortType;
            // const filterString = getState().pagesTable.filterString;

            // if (!currentPage) {
            //     currentPage = getState().pagesTable.currentPage;
            // } else {
            //     dispatch(setCurrentPage(ENTITY_UPPER_CASE, currentPage));
            // }

            // if (!pageSize) {
            //     pageSize = getState().pagesTable.pageSize;
            // }

            let id = getState().public?.activeLanguage?.id;
            let data = await publicAPI.fetchPagesData(
                id,
                parentId,
                // pageSize,
                // currentPage,
                // sortField,
                // sortType,
                // filterString
            );
            let items = data.items;
            // dispatch(setPagesTableItems(ENTITY_UPPER_CASE, data));
            dispatch(setActivePageId(parentId));
            if (parentId === null) {
                dispatch(setPagesData(items));
            } else {
                const newPagesData = getState().public.pagesData;
                function formatData(arr) {
                    arr.forEach((i) => {
                        if (i.id === parentId) {
                            i.children = items;
                        } else {
                            if (i.children) {
                                formatData(i.children);
                            }
                        }
                    });
                }
                formatData(newPagesData);
                dispatch(setPagesData([...newPagesData]));
            }
        } catch (error) {
            if (parentId === null) {
                dispatch(setPagesData([]));
            }
            console.log(error);
        } finally {
            // dispatch(setDataLoading(true));
            // dispatch(pagesDataLoading(false));
            setTimeout(function () {
                dispatch(parentLoadingId(null));
            }, 300);
        }
    };
};


export const hidePageChildren = (parentId) => {
    return async (dispatch, getState) => {

        try {
            const newPagesData = getState().public.pagesData;
            function formatData(arr) {
                arr.forEach((i) => {
                    if (i.id === parentId) {
                        i.children = undefined;
                    } else {
                        if (i.children) {
                            formatData(i.children);
                        }
                    }
                });
            }
            formatData(newPagesData);
            dispatch(setPagesData([...newPagesData]));
            // dispatch(fetchPagesData());
        } catch (error) {
            console.log(error);
        } finally {
        }
    };
};

export const subscribe = (email, groups_id, content) => {
    return async (dispatch) => {
        try {
            // 
            let data = await publicAPI.subscribe(email, groups_id);
            // 
            toast(`🎉 ${content}`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            // successMessage(data)
            // history.push(`/admiral-admin/${entity}`);
        } catch (error) {
            toast.error(`${error.response.data.message ? error.response?.data.message : 'Something went wrong'}`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
            // errorMessage(error)
        } finally {
        }
    };
};


export const sendSupportForms = (email, name, subject, question) => {
    return async (dispatch) => {
        try {
            let data = await supportForm.sendSupportForm(email, name, subject, question);
            // dispatch(getItems(entity))
            successMessage(data);
            // history.push(`/admiral-admin/${entity}`);
        } catch (error) {
            errorMessage(error)
        } finally {
        }
    }
}

export const sendQuizResults = (id, results) => {
    return async (dispatch) => {
        try {
            let data = await publicAPI.sendQuizResults(id, results);
        } catch (error) {
            console.log(error);
            // errorMessage(error)
        } finally {
        }
    };
};

export const getPageByUrl = (url) => {
    return async (dispatch, getState) => {
        const availableLanguages = getState().public.availableLanguages;
        const { id } = getState().public.activeLanguage;
        try {
            const { code, pageUrl } = parseUrlWithLanguage(url, availableLanguages);

            let lang = getState().public.availableLanguages.find((i) => i.url_code === code);

            if (!lang) {
                // const defaultCode = availableLanguages.find((i) => i.default_item == 1).code;
                // dispatch(setActiveLanguageByCode(defaultCode));
            }
            if (lang !== getState().public.activeLanguage && code) {
                const defaultCode = availableLanguages.find((i) => i.url_code === code);
                dispatch(setActiveLanguage(defaultCode));
            }

            let finalUrl = `/${pageUrl}`;

            // if (pageUrl === '') finalUrl = '/';
            const data = await publicAPI.getPageByUrl(finalUrl, lang?.id || id);
            // const { id: pepe } = data;
            // dispatch(getPageById(pepe, id));
            dispatch(setPageData(data));
        } catch (error) {
            console.log(error);
            dispatch(setPageData(null));
        }
    };
};

export const getPageId = (id) => {
    return async (dispatch, getState) => {
        try {
            // if (pageUrl === '') finalUrl = '/';
            // const data = await publicAPI.getPageByUrl(finalUrl, lang?.id || id);
            const data = await pageInfoAPI.fetchPageInfo(id);
            // const { id: pepe } = data;
            // dispatch(getPageById(pepe, id));
            dispatch(setPageData(data));
        } catch (error) {
            console.log(error);
            dispatch(setPageData(null));
        }
    };
};

export const getPageById = (id, langId) => {
    return async (dispatch, getState) => {
        const languages = getState().public.availableLanguages;
        const defaultLanguage = getState().public.defaultLanguage;
        try {
            const data = await publicAPI.getPageGroupById(id, langId);

            let language = languages.find((l) => l.id == langId);

            if (!language) {
                language = getState().public.activeLanguage;
            }
            console.log(data.url !== '/')
            if (language.code === defaultLanguage.code) {
                history.push(`/${data.url !== '/' ? data.url : ''}`);
            } else {
                history.push(`/${language.code}/${data.url !== '/' ? data.url : ''}`);
            }

            dispatch(setPageData(data));
        } catch (error) {
            console.log(error);
            dispatch(setPageData(null));
        }
    };
};

export const getAvailableLanguages = () => {
    return async (dispatch) => {
        try {
            // TODO: replace

            const data = await publicAPI.getLanguages(8, 1, 'id', 'desc', '');

            dispatch(setActiveLanguages(data.items));
        } catch (error) {
            console.log(error);
        }
    };
};

export const setActiveLanguageByCode = (code) => {
    return async (dispatch, getState) => {
        const languages = getState().public.availableLanguages;
        try {
            const language = languages.find((l) => l.code === code);
            dispatch(setActiveLanguage(language));
        } catch (error) {
            console.log(error);
        }
    };
};

export const setActiveLanguageByUrl = (url) => {
    return async (dispatch, getState) => {
        const languages = getState().public.availableLanguages;
        const defaultLanguage = getState().public.defaultLanguage;
        try {
            const { pageUrl, code } = parseUrlWithLanguage(url, languages);
            const language = languages.find((l) => l.url_code === code) || getState().public.activeLanguage;
            console.log(language)
            dispatch(setActiveLanguage(language));
            if (language.id === defaultLanguage.id) {
                history.push(`/${pageUrl}`);
            } else {
                history.push(`/${code}/${pageUrl}`);
            }
        } catch (error) {
            console.log(error);
        }
    };
};

export const toggleLoadingLine = (payload) => ({
    type: TOGGLE_LOADING_LINE,
    payload
});

export const searchingPages = (payload) => ({
    type: SEARCH_PAGES,
    payload
});

export const idsPages = (payload) => ({
    type: PAGES_BY_IDS,
    payload
});

export const filtersIdsPages = (payload) => ({
    type: FILTERS_PAGES_BY_IDS,
    payload
});

export const searchPages = (pageName, language_id, pagesValue) => {
    return async (dispatch) => {
        try {
            const data = await publicAPI.getSearchPages(pageName, language_id, pagesValue);
            dispatch(searchingPages(data))
        } catch (error) {
            console.log(error);
        }
    };
};

export const pagesByIds = (ids) => {
    return async (dispatch) => {
        try {
            const data = await publicAPI.getPagesByIds(ids);
            dispatch(idsPages(data))
        } catch (error) {
            console.log(error);
        }
    };
};

export const filtersPagesByIds = (ids) => {
    return async (dispatch) => {
        try {
            const data = await publicAPI.getPagesByIds(ids);
            dispatch(filtersIdsPages(data))
        } catch (error) {
            console.log(error);
        }
    };
};



export default publicReducer;
