import { itemsAPI } from '../api/api';
import history from '../history';
import { errorMessage, successMessage } from './actions';

const SET_FORM_RESULTS = 'SET_FORM_RESULTS';
const SET_DATA = 'SET_DATA';
const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
const CHANGE_SORT = 'CHANGE_SORT';
const SET_FILTER_STRING = 'SET_FILTER_STRING';
const SET_FILTER_OPEN = 'SET_FILTER_OPEN';
const FORMS_LIST_SET = 'FORMS_LIST_SET';

const ENTITY = 'form-results';
const ENTITY_UPPER_CASE = ENTITY.toUpperCase();

const initialState = {
    items: null,
    itemsLoading: true,
    totalCount: null,
    filteredCount: null,
    item: null,
    currentPage: 1,
    pageSize: '8',
    sortField: 'create_date',
    sortType: 'desc',
    filterString: '',
    isFilterOpen: false,
    usersList: [],
    selectedUser: null,
    formsList: []
}

const formResultsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FORM_RESULTS:
            return {
                ...state,
                items: action.data.items,
                totalCount: action.data.total_count,
                filteredCount: action.data.filtered_count
            };
        case SET_DATA:
            return {
                ...state,
                item: action.data
            };
        case `SET_CURRENT_PAGE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                currentPage: action.currentPage,
            }
        case `FORMS_LIST_SET`:
            return {
                ...state,
                formsList: action.data
            };
        case `SET_FILTER_OPEN`:
            return {
                ...state,
                isFilterOpen: action.isFilterOpen,
            }
        case `CHANGE_SORT`:
            let newSortType = '';

            if (action.oldSortField === action.sortField) {
                if (action.oldSortType === 'asc') {
                    newSortType = 'desc';
                } else {
                    newSortType = 'asc';
                }
            } else {
                newSortType = 'desc';
            }

            return {
                ...state,
                sortField: action.sortField,
                sortType: newSortType,
            }
        case `SET_FILTER_STRING`:
            return {
                ...state,
                filterString: action.filterString
            }
        case `SET_PAGE_SIZE`:
            return {
                ...state,
                pageSize: action.pageSize
            }
        default:
            return state;
    }
}
function makeActionCreator(type, ...argNames) {

    return function (...args) {
        let action = { type: `${type}` };
        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index];
        });
        return action;
    };
}


export const setPageSize = makeActionCreator(SET_PAGE_SIZE, 'pageSize');
export const setFilterString = makeActionCreator(SET_FILTER_STRING, 'filterString');
export const setFilterOpen = makeActionCreator(SET_FILTER_OPEN, 'isFilterOpen');
export const setCurrentPage = makeActionCreator(SET_CURRENT_PAGE, 'currentPage');
export const setFormResults = makeActionCreator(SET_FORM_RESULTS, 'data');
export const setFormsList = makeActionCreator(FORMS_LIST_SET, 'data');
export const setData = makeActionCreator(SET_DATA, 'data');
export const changeSortActionCreator = makeActionCreator(
    CHANGE_SORT,
    'oldSortField',
    'oldSortType',
    'sortField',
);

export const updatePageSize = (pageSize) => {
    return async (dispatch, getState) => {
        dispatch(setPageSize(pageSize));
        dispatch(setCurrentPage(1));
    };
};

export const changeSort = (oldSortField, oldSortType, sortField) => {
    return (dispatch) => {
        dispatch(changeSortActionCreator(oldSortField, oldSortType, sortField));
        dispatch(getItems());
    };
};


export const deleteItem = (id) => {
    return async (dispatch) => {
        try {
            let data = await itemsAPI.deleteFormResults(id);
            successMessage(data)
            dispatch(getItems());
            history.push(`/admiral-admin/form-results`);
        } catch (error) {
            errorMessage(error)
        } finally {
        }
    };
};

export const fetchForms = () => {
    return async (dispatch, getState) => {
        try {
            let data = await itemsAPI.getForms();
            dispatch(setFormsList(data.items));
        } catch (error) {
            errorMessage(error)
        } finally {
        }
    };
};


export const getItems = (currentPage, pageSize) => {
    return async (dispatch, getState) => {
        const sortField = getState().formResults.sortField;
        const sortType = getState().formResults.sortType;
        const filterString = getState().formResults.filterString;
        if (!currentPage) {
            currentPage = getState().formResults.currentPage;
        } else {
            dispatch(setCurrentPage(currentPage));
        }

        if (!pageSize) {
            pageSize = getState().formResults.pageSize;
        }
        try {
            const data = await itemsAPI.getFormResults(pageSize,
                currentPage,
                sortField,
                sortType,
                filterString,
            );
            dispatch(setFormResults(data));
        } catch (error) {
            console.log(error);
        }
    };
};

export const getItemData = (id) => {
    return async (dispatch) => {
        try {
            const data = await itemsAPI.getData(id);
            dispatch(setData(data));
        } catch (error) {
            console.log(error);
        }
    };
};

export const applyFilter = (values) => {

    return (dispatch) => {
        let filterString = '';

        let fltStrProps = '&flt_by_props='
        let fltStrValues = '&flt_by_values='

        if (values) {
            let itemKeys = Object.keys(values);
            let itemValues = Object.values(values);

            for (let i = 0; i < itemKeys.length; i++) {
                if (i === itemKeys.length - 1) {
                    fltStrProps += `${itemKeys[i]}`
                    fltStrValues += `${itemValues[i]}`
                } else {
                    fltStrProps += `${itemKeys[i]},`
                    fltStrValues += `${itemValues[i]},`
                }
            }
            filterString += `${fltStrProps}${fltStrValues}&flt_date_operator=range`
        }
        dispatch(setFilterString(filterString));
        dispatch(getItems());

    };
}



export default formResultsReducer;