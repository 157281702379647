import { itemsAPI } from '../api/api';
import { showNotification } from '../parts/Admin/utils/notifications/notifications';

const SET_ITEMS_LAYERS = 'SET_ITEMS_LAYERS';
const SET_EDITING_ITEM_ID = 'SET_EDITING_ITEM_ID';
const REMOVE_EDITING_ITEM_ID = 'REMOVE_EDITING_ITEM_ID';
const UPDATE_LAYERS_POSITION = 'UPDATE_LAYERS_POSITION';
const SET_LAYER_CREATION_TYPE = 'SET_LAYER_CREATION_TYPE';
const SET_STEP = 'SET_STEP';
const SET_LAYERS_LIST = 'SET_LAYERS_LIST';
const SET_LOADING_STATE = 'SET_LOADING_STATE';
const LAYER_CREATION_START = 'LAYER_CREATION_START';
const LAYER_CREATION_END = 'LAYER_CREATION_END';
const DELETE_LAYER = 'DELETE_LAYER';
const SET_LAYER_MAP = 'SET_LAYER_MAP';
const UPDATE_LAYER_MAP = 'UPDATE_LAYER_MAP';
const DELETE_LAYER_MAP = 'DELETE_LAYER_MAP';
const SET_IS_LOADED = 'SET_IS_LOADED';

const ENTITY = 'maps';

const initialState = {
    activeItemId: null,
    newLayerCreation: false,
    layerCreationActiveStep: 2,
    layerCreationType: 'layer',
    itemsLoading: true,
    items: [],
    isLoaded: true,
};

const mapsComponentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LAYERS_LIST:
            return {
                ...state,
                items: action.payload
            };
        case SET_LOADING_STATE:
            return {
                ...state,
                itemsLoading: action.payload
            };
        case SET_IS_LOADED:
            return {
                ...state,
                isLoaded: action.payload
            };
        case SET_ITEMS_LAYERS:
            return {
                ...state,
                items: action.payload
            };
        case SET_EDITING_ITEM_ID:
            return {
                ...state,
                activeItemId: action.payload
            };
        case `SET_LAYER_MAP`:
            const tmpState = {
                ...state
            }
            tmpState.items.push(action.payload);

            return {
                ...state,
                items: [...tmpState.items]
            };
        case `UPDATE_LAYER_MAP`:
            const tmpState1 = {
                ...state
            }
            const newField = tmpState1.items.find(field => field.id === action.payload.id)
            const index = tmpState1.items.indexOf(newField)
            newField.name = action.payload.name
            newField.type = action.payload.type
            newField.content = action.payload.content
            newField.id = action.payload.id
            newField.active = action.payload.active
            newField.position = action.payload.position

            tmpState1.items[index] = newField
            return {
                ...state,
                items: [...tmpState1.items]
            }
        case `DELETE_LAYER_MAP`:
            const tmpState2 = {
                ...state
            }
            const newFields = tmpState2.items.filter((field) => field.id !== action.payload);
            tmpState2.items = newFields
            return {
                ...state,
                items: [...tmpState2.items]
            }
        case REMOVE_EDITING_ITEM_ID:
            return {
                ...state,
                activeItemId: null
            };
        case LAYER_CREATION_START:
            return {
                ...state,
                newLayerCreation: true
            };
        case UPDATE_LAYERS_POSITION:
            return {
                ...state,
                items: action.payload.map((item, index) => {
                    item.position = index;
                    return item;
                })
            };
        case LAYER_CREATION_END:
            return {
                ...state,
                newLayerCreation: false,
                layerCreationActiveStep: 2,
                layerCreationType: 'layer'
            };
        case SET_LAYER_CREATION_TYPE:
            return {
                ...state,
                layerCreationType: action.layerType
            };
        case SET_STEP:
            return {
                ...state,
                layerCreationActiveStep: action.step
            };

        case DELETE_LAYER:
            return {
                ...state,
                items: state.items.filter((_, index) => index !== action.payload)
            };
        default:
            return state;
    }
};

export const setLayersList = (layers) => ({
    type: SET_LAYERS_LIST,
    payload: layers
});


export const setLayerMap = (data) => ({
    type: SET_LAYER_MAP,
    payload: data
});

export const updateLayerMap = (data) => ({
    type: UPDATE_LAYER_MAP,
    payload: data
});

export const deleteLayerMap = (data) => ({
    type: DELETE_LAYER_MAP,
    payload: data
});

export const setEditingItemId = (layerId) => ({
    type: SET_EDITING_ITEM_ID,
    payload: layerId
});

export const removeEditingItemId = () => ({
    type: REMOVE_EDITING_ITEM_ID
});

export const startLayerCreation = () => ({
    type: LAYER_CREATION_START
});

export const endLayerCreation = () => ({
    type: LAYER_CREATION_END
});

export const setStep = (step) => ({
    type: SET_STEP,
    step
});

export const setLayerCreationType = (layerType) => ({
    type: SET_LAYER_CREATION_TYPE,
    layerType
});

export const setLoadingState = (bool) => ({
    type: SET_LOADING_STATE,
    payload: bool
});

export const setIsLoaded = (bool) => ({
    type: SET_IS_LOADED,
    payload: bool
});

export const deleteItemByIndex = (index) => ({
    type: DELETE_LAYER,
    payload: index
});

export const updateLayersPosition = (items) => ({
    type: UPDATE_LAYERS_POSITION,
    payload: items
});

export const fetchLayers = (mapId) => {
    return async (dispatch) => {
        try {
            const response = await itemsAPI.getItemData(ENTITY, mapId);

            dispatch(setLayersList(response.items || []));
        } catch (error) {
            console.log(error);
            dispatch(setLayersList([]));
        } finally {
            setTimeout(function () {
                dispatch(setLoadingState(false));
            }, 700);

        }
    };
};

export const deleteItem = (index) => {
    return async (dispatch) => {
        try {
            dispatch(deleteItemByIndex(index));
        } catch (error) {
            if (error.response?.data?.message) {
                showNotification(error.response.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
        }
    };
};

export default mapsComponentsReducer;
