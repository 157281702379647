import { formsAPI, itemsAPI, selfAssessmentAPI } from "../api/api";
import history from '../history';
import { getItems, getItemData, errorMessage, successMessage, setItems } from './actions';
import { StyledTextToast } from "../parts/Admin/components/Main/AdminToastContainer";
import { toast } from "react-toastify";
import { sendingForm } from "./forms-reducer";
import { setDataLoading } from "./app-reducer";

const ENTITY = 'self-assessment';
const ENTITY_UPPER_CASE = ENTITY.toUpperCase();


const initialState = {
  items: null,
  itemsLoading: true,
  totalCount: null,
  filteredCount: null,
  item: null,
  currentPage: 1,
  pageSize: '8',
  sortField: 'create_date',
  sortType: 'desc',
  filterString: '',
  isFilterOpen: false,
  usersList: [],
  selectedUser: null,
}

const selfAssessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case `SET_ITEMS_${ENTITY_UPPER_CASE}`:
      return {
        ...state,
        items: action.data.items,
        totalCount: action.data.total_count,
        filteredCount: action.data.filtered_count
      }
    case `SET_ITEM_${ENTITY_UPPER_CASE}`:
      return {
        ...state,
        item: action.data
      }
    case `SET_CURRENT_PAGE_${ENTITY_UPPER_CASE}`:
      return {
        ...state,
        currentPage: action.currentPage,
      }
    case `SET_FILTER_OPEN_${ENTITY_UPPER_CASE}`:
      return {
        ...state,
        isFilterOpen: action.isFilterOpen,
      }
    case `USERS_LIST_SET_${ENTITY_UPPER_CASE}`:
      return {
        ...state,
        usersList: action.data
      };
    case `SELECTED_USER_SET_${ENTITY_UPPER_CASE}`:
      return {
        ...state,
        selectedUser: action.data
      };
    case `CHANGE_SORT_${ENTITY_UPPER_CASE}`:
      let newSortType = '';

      if (action.oldSortField === action.sortField) {
        if (action.oldSortType === 'asc') {
          newSortType = 'desc';
        } else {
          newSortType = 'asc';
        }
      } else {
        newSortType = 'desc';
      }

      return {
        ...state,
        sortField: action.sortField,
        sortType: newSortType,
      }
    case `SET_FILTER_STRING_${ENTITY_UPPER_CASE}`:
      return {
        ...state,
        filterString: action.filterString
      }
    case `SET_PAGE_SIZE_${ENTITY_UPPER_CASE}`:
      return {
        ...state,
        pageSize: action.pageSize
      }
    default:
      return state;
  }
}

export const updateItem = (entity, id, item, components, apply) => {
  return async (dispatch) => {
    try {
      let data = await selfAssessmentAPI.updateAssessment(entity, id, item, components);
      dispatch(getItems(entity));
      successMessage(data)
      if(!apply){
        history.push(`/admiral-admin/${entity}`);
      } else {
        dispatch(getItemData(entity,id))
      }
    } catch (error) {
      // errorMessage(error)
    } finally {
    }
  }
};


export const createItem = (entity, values, components) => {
  return async (dispatch) => {
    try {
      let data = await itemsAPI.createAssessment(entity, values, components)
      dispatch(getItems(ENTITY));
      successMessage(data)
      history.push(`/admiral-admin/${ENTITY}`);
    } catch (error) {
      // errorMessage(error)
    } finally {
    }
  }
};

export const sendResult = (formData, f) => {
  return async (dispatch) => {
    const toastText = () => (
      <StyledTextToast> <p>{'Thanks!'}</p> </StyledTextToast>
  )
    try {
      let data = await selfAssessmentAPI.sendResult(formData)
      toast(toastText, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnFocusLoss: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });
      f.restart()
    } catch (error) {
      toast.error(`${error.response.data.message ? error.response?.data.message : 'Something went wrong'}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnFocusLoss: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    })
      console.log(error);
      f.restart();
    }
  }
};

export const sendResultHelp = (formData, f) => {
  return async (dispatch) => {
    try {
      let data = await selfAssessmentAPI.sendResultHelp(formData)
      f.restart()
    } catch (error) {
      console.log(error);
      f.restart();
    }
  }
};

export const sendResultPurple = (formData) => {
  return async (dispatch) => {
    try {
      let data = await selfAssessmentAPI.sendResultPurple(formData)
    } catch (error) {
      console.log(error);
    }
  }
};


export const getAssessments = (entity) => {
  return async (dispatch, getState) => {
      // debugger
      dispatch(setDataLoading(false));
    
      try {
          let data = await itemsAPI.getAssessments(
              entity
          );
          dispatch(setDataLoading(true));

         
          dispatch(setItems('self-assessment'.toUpperCase(), data));
        
      } catch (error) {
          errorMessage(error)
      } finally {
      }
  };
};

export default selfAssessmentReducer;