const ENTITY = 'subscriberGroups';
const ENTITY_UPPER_CASE = ENTITY.toUpperCase();


const initialState = {
    items: null,
    itemsLoading: true,
    totalCount: null,
    filteredCount: null,
    item: null,
    currentPage: 1,
    pageSize: '8',
    sortField: 'create_date',
    sortType: 'desc',
    filterString: '',
    isFilterOpen: false,
    usersList: [],
    selectedUser: null,
}

const subscriberGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SET_ITEMS_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                items: action.data.items,
                totalCount: action.data.total_count,
                filteredCount: action.data.filtered_count
            }
        case `SET_ITEM_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                item: action.data.item
            }
        case `SET_CURRENT_PAGE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                currentPage: action.currentPage,
            }
        case `SET_FILTER_OPEN_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                isFilterOpen: action.isFilterOpen,
            }
        case `USERS_LIST_SET_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                usersList: action.data
            };
        case `SELECTED_USER_SET_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                selectedUser: action.data
            };
        case `CHANGE_SORT_${ENTITY_UPPER_CASE}`:
            let newSortType = '';

            if (action.oldSortField === action.sortField) {
                if (action.oldSortType === 'asc') {
                    newSortType = 'desc';
                } else {
                    newSortType = 'asc';
                }
            } else {
                newSortType = 'desc';
            }

            return {
                ...state,
                sortField: action.sortField,
                sortType: newSortType,
            }
        case `SET_FILTER_STRING_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                filterString: action.filterString
            }
        case `SET_PAGE_SIZE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                pageSize: action.pageSize
            }
        default:
            return state;
    }
}


export default subscriberGroupsReducer;