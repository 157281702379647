import { showNotification } from '../parts/Admin/utils/notifications/notifications';
import {subscriptionSettingsAPI} from '../api/api';

const SET_ITEMS = 'SET_ITEMS';

const initialState = {
    items: null
};

const subscriptionSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ITEMS:
            return {
                ...state,
                items: action.data
            };
        default:
            return state;
    }
};

export const setItems = (data) => ({
    type: SET_ITEMS,
    payload: data
});

export const getSubscriptionSettings = () => {
    return async (dispatch) => {
        try {
            let items = await subscriptionSettingsAPI.getSubscriptionSettings();

            dispatch(setItems(items));

        } catch (error) {
            if (error.response?.data?.message) {
                showNotification(error.response.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
        }
    };
};

export const setSubscriptionSettings = (formData) => {
    return async (dispatch) => {
        try {
            const data = await subscriptionSettingsAPI.updateSubscriptionSettings(formData);
            await dispatch(getSubscriptionSettings());
            showNotification(data.message[0], 'success', 'shifted');
        } catch (error) {
            if (error.response?.data?.message) {
                showNotification(error.response.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
        }
    };
};

export default subscriptionSettingsReducer;