import { pagesAPI, pageInfoAPI } from '../api/api';
import { showNotification } from '../parts/Admin/utils/notifications/notifications';
import { setCurrentPage, setPageSize, setItems as setPagesTableItems } from './actions';
import { setDataLoading } from './app-reducer';

const PAGES_DATA_SET = 'PAGES_DATA_SET';
const PAGES_DATA_LOADING = 'PAGES_DATA_LOADING';
// const PAGES_DATA_ERROR = 'PAGES_DATA_ERROR';

const LANGUAGES_LIST_SET = 'LANGUAGES_LIST_SET';
const LANGUAGES_LIST_LOADING = 'LANGUAGES_LIST_LOADING';
// const LANGUAGES_LIST_ERROR = 'LANGUAGES_LIST_ERROR';

const SELECTED_LANGUAGE_SET = 'SELECTED_LANGUAGE_SET';
const GROUP_PAGE = 'GROUP_PAGE';

const PARENT_LOADING_ID = 'PARENT_LOADING_ID';
const ACTIVE_PAGE_ID = 'ACTIVE_PAGE_ID';

const ENTITY = 'pages';
const ENTITY_UPPER_CASE = ENTITY.toUpperCase();

const initialState = {
    languagesListLoading: false,
    selectedLanguage: null,
    pagesDataLoading: false,
    parentLoadingId: null,
    activePageId: null,
    pagesData: [],
    languagesList: [],
    isFilterOpen: false,
    usersList: [],
    selectedUser: null,
};

const pagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAGES_DATA_SET:
            return {
                ...state,
                pagesData: action.data
            };
        case PAGES_DATA_LOADING:
            return {
                ...state,
                pagesDataLoading: action.bool
            };

        case LANGUAGES_LIST_SET:
            return {
                ...state,
                languagesList: action.data
            };
        case LANGUAGES_LIST_LOADING:
            return {
                ...state,
                languagesListLoading: action.bool
            };

        case SELECTED_LANGUAGE_SET:
            return {
                ...state,
                selectedLanguage: action.data
            };
        case `SET_FILTER_OPEN_${ENTITY_UPPER_CASE}`:
                return {
                    ...state,
                    isFilterOpen: action.isFilterOpen,
                }
                case `USERS_LIST_SET_${ENTITY_UPPER_CASE}`:
                    return {
                        ...state,
                        usersList: action.data
                    };
                case `SELECTED_USER_SET_${ENTITY_UPPER_CASE}`:
                    return {
                        ...state,
                        selectedUser: action.data
                    };
        case PARENT_LOADING_ID:
            return {
                ...state,
                parentLoadingId: action.data
            };
        case ACTIVE_PAGE_ID:
            return {
                ...state,
                activePageId: action.data
            };
        default:
            return state;
    }
};

function makeActionCreator(type, ...argNames) {
    return function (...args) {
        let action = { type };
        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index];
        });
        return action;
    };
}

const pagesDataSet = makeActionCreator(PAGES_DATA_SET, 'data');
const pagesDataLoading = makeActionCreator(PAGES_DATA_LOADING, 'bool');
const languagesListSet = makeActionCreator(LANGUAGES_LIST_SET, 'data');
const languagesListLoading = makeActionCreator(LANGUAGES_LIST_LOADING, 'bool');
export const selectedLanguageSet = makeActionCreator(SELECTED_LANGUAGE_SET, 'data');
const parentLoadingId = makeActionCreator(PARENT_LOADING_ID, 'data');
export const setActivePageId = makeActionCreator(ACTIVE_PAGE_ID, 'data');
const groupPage = makeActionCreator(GROUP_PAGE, 'data');

export const groupPageById = (id, language_id) => {
    return async (dispatch, getState) => {
        try {
            const data = await pageInfoAPI.groupPage(id, language_id);
        } catch (error) {
            (error.response?.data.message).forEach((value, i) => {
                showNotification(error.response?.data.message[i], 'danger');
            });
        }
    };
};

export const fetchLanguages = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(languagesListLoading(true));

            let data = await pageInfoAPI.fetchLanguagesList();
            dispatch(languagesListSet(data.items));

            const shouldUpdateDefaultLanguage = !getState().pages.selectedLanguage;

            if (shouldUpdateDefaultLanguage) {
                const default_item = data.items.find((item) => item.default_item === 1) || data.items[0];
                dispatch(selectedLanguageSet(default_item));
            }
        } catch (error) {
            if (error.data?.message) {
                showNotification(error.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
            dispatch(languagesListLoading(false));
        }
    };
};

export const pagesDataUpdate = (items, item) => {
    return async (dispatch, getState) => {
        try {
            let prevParentId = item.parent_id;
            let parentId = null;
            searchParentId(items);
            function searchParentId(arr) {
                arr.forEach((i) => {
                    if (i.children) {
                        if (i.children.find((el) => el.id + '' === item.id + '')) {
                            parentId = i.id;
                        } else {
                            searchParentId(i.children);
                        }
                    }
                });
            }
            await pagesAPI.pagesDataUpdate(item, parentId);
            let newItemsList = [...items];
            addHasChildProp(newItemsList);
            function addHasChildProp(arr) {
                arr.forEach((i) => {
                    if (i.children) {
                        if (i.id + '' === parentId + '') {
                            i.hasChild = true;
                        }
                        if (i.id + '' === prevParentId + '') {
                            if (!i.children.length) {
                                i.hasChild = false;
                            }
                        }
                        addHasChildProp(i.children);
                    }
                });
            }
            dispatch(pagesDataSet([...newItemsList]));
            dispatch(fetchPagesData(parentId));
        } catch (error) {
            if (error.data?.message) {
                showNotification(error.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
        }
    };
};

export const fetchChildrenPagesData = (parentId = null) => {
    return async (dispatch, getState) => {
        dispatch(setDataLoading(false));

        try {
            dispatch(parentLoadingId(parentId));
            if (!getState().pages?.selectedLanguage) return;
            dispatch(pagesDataLoading(true));

            let id = getState().pages?.selectedLanguage?.id;
            
            let data = await pagesAPI.fetchPagesData(
                id,
                parentId,
                1000000,
                1,
                'create_date',
                'desc',
                ''
            );
            let items = data.items;
            dispatch(setPagesTableItems(ENTITY_UPPER_CASE, data));
            dispatch(setActivePageId(parentId));

            if (parentId === null) {
                dispatch(pagesDataSet(items));
            } else {
                const newPagesData = getState().pages.pagesData;
                function formatData(arr) {
                    arr.forEach((i) => {
                        if (i.id === parentId) {
                            i.children = items;
                        } else {
                            if (i.children) {
                                formatData(i.children);
                            }
                        }
                    });
                }
                formatData(newPagesData);
                dispatch(pagesDataSet([...newPagesData]));
            }
        } catch (error) {
            if (parentId === null) {
                dispatch(pagesDataSet([]));
            }
            if (error.data?.message) {
                showNotification(error.data.message, 'danger', 'shifted');
            } else {
                dispatch(setPagesTableItems(ENTITY_UPPER_CASE, []));
                // showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
            dispatch(setDataLoading(true));
            dispatch(pagesDataLoading(false));
            setTimeout(function () {
                dispatch(parentLoadingId(null));
            }, 300);
        }
    };
};

export const fetchPagesData = (parentId = null, currentPage, pageSize) => {
    return async (dispatch, getState) => {
        dispatch(setDataLoading(false));

        try {
            dispatch(parentLoadingId(parentId));
            if (!getState().pages?.selectedLanguage) return;
            dispatch(pagesDataLoading(true));
            const sortField = getState().pagesTable.sortField;
            const sortType = getState().pagesTable.sortType;
            const filterString = getState().pagesTable.filterString;

            if (!currentPage) {
                currentPage = getState().pagesTable.currentPage;
            } else {
                dispatch(setCurrentPage(ENTITY_UPPER_CASE, currentPage));
            }

            if (!pageSize) {
                pageSize = getState().pagesTable.pageSize;
            } else {
                dispatch(setPageSize(ENTITY_UPPER_CASE, pageSize))
            }

            let id = getState().pages?.selectedLanguage?.id;
            
            let data = await pagesAPI.fetchPagesData(
                id,
                parentId,
                pageSize,
                currentPage,
                sortField,
                sortType,
                filterString
            );
            
            if(!data.items.length) {
                data = await pagesAPI.fetchPagesData(
                    id,
                    parentId,
                    pageSize,
                    currentPage - 1,
                    sortField,
                    sortType,
                    filterString
                );
            }

            let items = data.items;
            dispatch(setPagesTableItems(ENTITY_UPPER_CASE, data));
            dispatch(setActivePageId(parentId));

            if (parentId === null) {
                dispatch(pagesDataSet(items));
            } else {
                const newPagesData = getState().pages.pagesData;
                function formatData(arr) {
                    arr.forEach((i) => {
                        if (i.id === parentId) {
                            i.children = items;
                        } else {
                            if (i.children) {
                                formatData(i.children);
                            }
                        }
                    });
                }
                formatData(newPagesData);
                dispatch(pagesDataSet([...newPagesData]));
            }
        } catch (error) {
            if (parentId === null) {
                dispatch(pagesDataSet([]));
            }
            if (error.data?.message) {
                showNotification(error.data.message, 'danger', 'shifted');
            } else {
                dispatch(setPagesTableItems(ENTITY_UPPER_CASE, []));
                // showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
            dispatch(setDataLoading(true));
            dispatch(pagesDataLoading(false));
            setTimeout(function () {
                dispatch(parentLoadingId(null));
            }, 300);
        }
    };
};

export const getPagesData = (parentId = null, currentPage, pageSize) => {
    return async (dispatch, getState) => {
        try {
            dispatch(parentLoadingId(parentId));
            if (!getState().pages?.selectedLanguage) return;
            dispatch(pagesDataLoading(true));
            const sortField = getState().pagesTable.sortField;
            const sortType = getState().pagesTable.sortType;
            const filterString = getState().pagesTable.filterString;

            if (!currentPage) {
                currentPage = getState().pagesTable.currentPage;
            } else {
                dispatch(setCurrentPage(ENTITY_UPPER_CASE, currentPage));
            }

            if (!pageSize) {
                pageSize = getState().pagesTable.pageSize;
            }

            let id = getState().pages?.selectedLanguage?.id;
            let data = await pagesAPI.getPagesData(
                id,
                parentId,
                pageSize,
                currentPage,
                sortField,
                sortType,
                filterString
            );
            let items = data.items;
            dispatch(setPagesTableItems(ENTITY_UPPER_CASE, data));

            dispatch(setActivePageId(parentId));
            if (parentId === null) {
                dispatch(pagesDataSet(items));
            } else {
                const newPagesData = getState().pages.pagesData;
                function formatData(arr) {
                    arr.forEach((i) => {
                        if (i.id === parentId) {
                            i.children = items;
                        } else {
                            if (i.children) {
                                formatData(i.children);
                            }
                        }
                    });
                }
                formatData(newPagesData);
                dispatch(pagesDataSet([...newPagesData]));
            }
        } catch (error) {
            if (parentId === null) {
                dispatch(pagesDataSet([]));
            }
            if (error.data?.message) {
                showNotification(error.data.message, 'danger', 'shifted');
            } else {
                dispatch(setPagesTableItems(ENTITY_UPPER_CASE, []));
                // showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
            dispatch(pagesDataLoading(false));
            setTimeout(function () {
                dispatch(parentLoadingId(null));
            }, 300);
        }
    };
};

export const hidePageChildren = (parentId) => {
    return async (dispatch, getState) => {

        try {
            const newPagesData = getState().pages.pagesData;
            function formatData(arr) {
                arr.forEach((i) => {
                    if (i.id === parentId) {
                        i.children = undefined;
                    } else {
                        if (i.children) {
                            formatData(i.children);
                        }
                    }
                });
            }
            formatData(newPagesData);
            dispatch(pagesDataSet([...newPagesData]));
            // dispatch(fetchPagesData());
        } catch (error) {
            if (error.response?.data?.message) {
                showNotification(error.response.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
        }
    };
};
export default pagesReducer;
