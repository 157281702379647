// import {api} from '../api/api';
// import { showNotification } from '../parts/Admin/utils/notifications/notifications';

import { itemsAPI } from "../api/api";
import { errorMessage } from "./actions";

const SET_DATA_LOADING = 'SET_DATA_LOADING';
const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
const SET_PAGES_STATISTICS_DATA = 'SET_PAGES_STATISTICS_DATA';
const SET_SPECIFIC_PAGE_STATISTICS_DATA = 'SET_SPECIFIC_PAGE_STATISTICS_DATA';
const SET_SELECTED_PAGE_NAME = 'SET_SELECTED_PAGE_NAME';

const getFromLocalStorage = () => {
    const value = localStorage.getItem('isSidebarOpened');
    return value ? JSON.parse(value) : true;
};

const initialState = {
    isSidebarOpened: getFromLocalStorage(),
    dataLoading: false,
    breadcrumbs: [],
    pagesStatisticsData: [],
    specificPageStatisticsData: null,
    selectedPageName: '',
};

const AppReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_LOADING:
            return {
                ...state,
                dataLoading: action.payload
            };
        case SET_BREADCRUMBS:
            return {
                ...state,
                breadcrumbs: action.payload
            };
            case SET_PAGES_STATISTICS_DATA:
                return {
                    ...state,
                    pagesStatisticsData: action.payload,
                };
                case SET_SPECIFIC_PAGE_STATISTICS_DATA:
                    return {
                        ...state,
                        specificPageStatisticsData: action.payload
                    };
                case SET_SELECTED_PAGE_NAME:
                    return {
                        ...state,
                        selectedPageName: action.payload
                    };
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                isSidebarOpened: !state.isSidebarOpened
            };
        default:
            return state;
    }
};

const setSidebar = () => ({
    type: TOGGLE_SIDEBAR
});

export const setDataLoading = (bool) => ({
    type: SET_DATA_LOADING,
    payload: bool
});

export const setPagesStatisticsData = (payload) => ({
    type: SET_PAGES_STATISTICS_DATA,
    payload
});

export const setSpecificPageStatisticsData = (payload) => ({
    type: SET_SPECIFIC_PAGE_STATISTICS_DATA,
    payload
});

export const setSelectedPageName = (payload) => ({
    type: SET_SELECTED_PAGE_NAME,
    payload
});

export const setBreadcrumbs = (breadcrumbs) => (
    {
    type: SET_BREADCRUMBS,
    payload: breadcrumbs
});

export const toggleSidebar = () => {
    return (dispatch, getState) => {
        dispatch(setSidebar());
        localStorage.setItem('isSidebarOpened', getState().app.isSidebarOpened);
    };
};

export const getPagesStatistics = (limit,days) => {
    return async (dispatch) => {
        try {
            let data = await itemsAPI.getPagesStatistics(limit,days)
            dispatch(setPagesStatisticsData(data));
            // successMessage(data)
            // history.push(`/admiral-admin/${ENTITY}`);
        } catch (error) {
            // errorMessage(error)
        } finally {
        }
    }
}

export const specificPageStatistics = (id,days) => {
    return async (dispatch) => {
        try {
            let data = await itemsAPI.specificPageStatistics(id,days)
            dispatch(setSpecificPageStatisticsData(data));
            // dispatch(setSelectedPageName(data.name));
            // successMessage(data)
            // history.push(`/admiral-admin/${ENTITY}`);
        } catch (error) {
            // errorMessage(error)
            dispatch(setSpecificPageStatisticsData([]));
            dispatch(setSelectedPageName(''));
        } finally {
        }
    }
}

export default AppReducer;
