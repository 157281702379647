import { pageInfoAPI } from "../api/api";
import { showNotification } from "../parts/Admin/utils/notifications/notifications";

const ENTITY = 'subscribers';
const ENTITY_UPPER_CASE = ENTITY.toUpperCase();

const GROUPS_LIST_SET = 'GROUPS_LIST_SET';
const GROUPS_LIST_LOADING = 'GROUPS_LIST_LOADING';


const initialState = {
    items: null,
    itemsLoading: true,
    totalCount: null,
    filteredCount: null,
    item: null,
    currentPage: 1,
    pageSize: '8',
    sortField: 'create_date',
    sortType: 'desc',
    filterString: '',
    isFilterOpen: false,
    usersList: [],
    selectedUser: null,
    groupsList: [],
    groupsListLoading: false,
}

const subscribersReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SET_ITEMS_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                items: action.data.items,
                totalCount: action.data.total_count,
                filteredCount: action.data.filtered_count
            }
        case `SET_ITEM_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                item: action.data
            }
        case GROUPS_LIST_SET:
            return {
                ...state,
                groupsList: action.data
            }
        case GROUPS_LIST_LOADING:
            return {
                ...state,
                groupsListLoading: action.bool
            }
        case `SET_CURRENT_PAGE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                currentPage: action.currentPage,
            }
        case `SET_FILTER_OPEN_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                isFilterOpen: action.isFilterOpen,
            }
        case `USERS_LIST_SET_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                usersList: action.data
            };
        case `SELECTED_USER_SET_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                selectedUser: action.data
            };
        case `CHANGE_SORT_${ENTITY_UPPER_CASE}`:
            let newSortType = '';

            if (action.oldSortField === action.sortField) {
                if (action.oldSortType === 'asc') {
                    newSortType = 'desc';
                } else {
                    newSortType = 'asc';
                }
            } else {
                newSortType = 'desc';
            }

            return {
                ...state,
                sortField: action.sortField,
                sortType: newSortType,
            }
        case `SET_FILTER_STRING_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                filterString: action.filterString
            }
        case `SET_PAGE_SIZE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                pageSize: action.pageSize
            }
        default:
            return state;
    }
}

function makeActionCreator(type, ...argNames) {
    return function (...args) {
        let action = { type };
        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index];
        });
        return action;
    };
}

const groupsListSet = makeActionCreator(GROUPS_LIST_SET, 'data');
const groupsListLoading = makeActionCreator(GROUPS_LIST_LOADING, 'bool');


export const fetchGroupsList = () => {
    return async (dispatch) => {
        try {
            dispatch(groupsListLoading(true));
            let data = await pageInfoAPI.fetchGroupsList();
            dispatch(groupsListSet(data.items));
        } catch (error) {
            (error.response?.data.message).forEach((value, i) => {
                showNotification(error.response?.data.message[i], 'danger');
            });
        } finally {
            dispatch(groupsListLoading(false));
        }
    };
};

export default subscribersReducer;