import { formsAPI, itemsAPI, mediaApi, modulesAPI, pageInfoAPI } from '../api/api';
import { showNotification } from '../parts/Admin/utils/notifications/notifications';
import { setItem, errorMessage, successMessage } from './actions';
import { setDataLoading } from './app-reducer';
import { pageInfoSet } from './pageInfo-reducer';

const SET_ITEMS_MODULES = 'SET_ITEMS_MODULES';
const SET_EXISTING_MODULES = 'SET_EXISTING_MODULES';
const SET_EXISTING_FORMS = 'SET_EXISTING_FORMS';
const SET_EXISTING_MAPS = 'SET_EXISTING_MAPS';
const SET_EXISTING_QUIZZES = 'SET_EXISTING_QUIZZES';
const SET_EXISTING_ASSESSMENTS = 'SET_EXISTING_ASSESSMENTS';
const SET_EXISTING_CALCULATORS = 'SET_EXISTING_CALCULATORS'
const SET_EDITING_ITEM_ID = 'SET_EDITING_ITEM_ID';
const SET_EXISTING_IMAGES = 'SET_EXISTING_IMAGES';
const REMOVE_EDITING_ITEM_ID = 'REMOVE_EDITING_ITEM_ID';
const MODULE_CREATION_START = 'MODULE_CREATION_START';
const MODULE_CREATION_END = 'MODULE_CREATION_END';
const EXISTING_COMPONENT_ADDITION_START = 'EXISTING_MODULE_ADDITION';
const EXISTING_COMPONENT_ADDITION_END = 'EXISTING_MODULE_ADDITION_END';
const UPDATE_MODULES_POSITION = 'UPDATE_MODULES_POSITION';
const SET_EXISTING_PAGES = 'SET_EXISTING_PAGES';
const SET_MODULE_CREATION_TYPE = 'SET_MODULE_CREATION_TYPE';
const SET_STEP = 'SET_STEP';
const SET_MODULES_LIST = 'SET_MODULES_LIST';
const SET_LOADING_STATE = 'SET_LOADING_STATE';
const DELETE_MODULE = 'DELETE_MODULE';

const initialState = {
    activeItemId: null,
    newModuleCreation: false,
    existingComponentAddition: false,
    moduleCreationActiveStep: 1,
    moduleCreationType: null,
    itemsLoading: true,
    items: [],
    existingModules: [],
    existingForms: [],
    existingImages: [],
    existingPages: [],
    existingMaps: [],
    existingQuizzes: [],
    existingAssessments: [],
    existingCalculators: []
};


const pageComponentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MODULES_LIST:
            return {
                ...state,
                items: action.payload
            };
        case SET_LOADING_STATE:
            return {
                ...state,
                itemsLoading: action.payload
            };
        case SET_ITEMS_MODULES:
            return {
                ...state,
                items: action.payload
            };

        case SET_EXISTING_MODULES:
            return {
                ...state,
                existingModules: action.payload
            };

        case SET_EXISTING_FORMS:
            return {
                ...state,
                existingForms: action.payload
            };
        case SET_EXISTING_MAPS:
            return {
                ...state,
                existingMaps: action.payload
            };
        case SET_EXISTING_QUIZZES:
            return {
                ...state,
                existingQuizzes: action.payload
            };
        case SET_EXISTING_ASSESSMENTS:
            return {
                ...state,
                existingAssessments: action.payload
            };
            case SET_EXISTING_CALCULATORS:
            return {
                ...state,
                existingCalculators: action.payload
            };
        case SET_EXISTING_IMAGES:
            return {
                ...state,
                existingImages: action.payload
            };
        case SET_EXISTING_PAGES:
            return {
                ...state,
                existingPages: action.payload
            };
        case SET_EDITING_ITEM_ID:
            return {
                ...state,
                activeItemId: action.payload
            };
        case REMOVE_EDITING_ITEM_ID:
            return {
                ...state,
                activeItemId: null
            };
        case MODULE_CREATION_START:
            return {
                ...state,
                newModuleCreation: true
            };
        case EXISTING_COMPONENT_ADDITION_START:
            return {
                ...state,
                existingModuleAddition: true
            };

        case EXISTING_COMPONENT_ADDITION_END:
            return {
                ...state,
                existingModuleAddition: false
            };

        case UPDATE_MODULES_POSITION:
            return {
                ...state,
                items: action.payload.map((item, index) => {
                    item.position = index;
                    return item;
                })
            };
        case MODULE_CREATION_END:
            return {
                ...state,
                newModuleCreation: false,
                moduleCreationActiveStep: 1,
                moduleCreationType: null
            };
        case SET_MODULE_CREATION_TYPE:
            return {
                ...state,
                moduleCreationType: action.moduleType
            };
        case SET_STEP:
            return {
                ...state,
                moduleCreationActiveStep: action.step
            };

        case DELETE_MODULE:
            return {
                ...state,
                items: state.items.filter((_, index) => index !== action.payload)
            };
        default:
            return state;
    }
};

export const setExistingPages = (payload) => ({
    type: SET_EXISTING_PAGES,
    payload
});

export const setModulesList = (modules) => ({
    type: SET_MODULES_LIST,
    payload: modules
});

export const setExistingModulesList = (modules) => ({
    type: SET_EXISTING_MODULES,
    payload: modules
});

export const setExistingImages = (payload) => ({
    type: SET_EXISTING_IMAGES,
    payload
});

export const setExistingFormsList = (forms) => ({
    type: SET_EXISTING_FORMS,
    payload: forms
});

export const setExistingMapsList = (maps) => ({
    type: SET_EXISTING_MAPS,
    payload: maps
});

export const setExistingQuizzesList = (quizzes) => ({
    type: SET_EXISTING_QUIZZES,
    payload: quizzes
});

export const setExistingAssessmentsList = (assessments) => ({
    type: SET_EXISTING_ASSESSMENTS,
    payload: assessments
});

export const setExistingCalculatorsList = (calculators) => ({
    type: SET_EXISTING_CALCULATORS,
    payload: calculators
});

export const setEditingItemId = (moduleId) => ({
    type: SET_EDITING_ITEM_ID,
    payload: moduleId
});

export const removeEditingItemId = () => ({
    type: REMOVE_EDITING_ITEM_ID
});

export const startModuleCreation = () => ({
    type: MODULE_CREATION_START
});

export const endModuleCreation = () => ({
    type: MODULE_CREATION_END
});

export const setStep = (step) => ({
    type: SET_STEP,
    step
});

export const setModuleCreationType = (moduleType) => ({
    type: SET_MODULE_CREATION_TYPE,
    moduleType
});

export const setLoadingState = (bool) => ({
    type: SET_LOADING_STATE,
    payload: bool
});

export const deleteItemByIndex = (index) => ({
    type: DELETE_MODULE,
    payload: index
});

export const updateModulesPosition = (items) => ({
    type: UPDATE_MODULES_POSITION,
    payload: items
});

export const startExistingComponentAddition = () => ({
    type: EXISTING_COMPONENT_ADDITION_START
});

export const endExistingComponentAddition = () => ({
    type: EXISTING_COMPONENT_ADDITION_END
});

export const fetchModules = (pageId) => {
    return async (dispatch) => {
        try {
            const response = await pageInfoAPI.fetchPageInfo(pageId);
            dispatch(pageInfoSet(response)); //was here
            dispatch(setModulesList(response.items || []));
        } catch (error) {
            console.log(error);
            dispatch(setModulesList([]));
        } finally {
            dispatch(setLoadingState(false));
        }
    };
};

export const fetchExistingPages = () => {
    return async (dispatch, getState) => {
        try {
            const response = await pageInfoAPI.fetchPages();

            dispatch(setExistingPages(response.items || []));
        } catch (error) {
            console.log(error);
            dispatch(setModulesList([]));
        } finally {
            dispatch(setLoadingState(false));
        }
    };
};

export const fetchExistingModules = () => {
    return async (dispatch) => {
        try {
            // TODO: replace with more accurate method
            const response = await pageInfoAPI.fetchExistingModules();
            dispatch(setExistingModulesList(response));
        } catch (error) {
            console.log(error);
        }
    };
};

export const getAllExistingComponents= () => {
    return async (dispatch) => {
        try {
            dispatch(setDataLoading(false));
            const promise1 = dispatch(fetchExistingModules());
            const promise2 = dispatch(fetchExistingForms());
            const promise3 = dispatch(fetchExistingMaps());
            const promise4 = dispatch(fetchExistingQuizzes());
            const promise5 = dispatch(fetchExistingAssessments());
            const promise6 = dispatch(fetchExistingCalculators());
            await Promise.all([promise1, promise2, promise3, promise4, promise5, promise6]);
            dispatch(setDataLoading(true));
        } catch (error) {
            console.log(error);
        } finally {
            // setTimeout(function () {
            //     dispatch(setDataLoading(false));
            // }, 700);
        }
    };
};

export const fetchExistingImages = () => {
    return async (dispatch) => {
        try {
            const response = await mediaApi.getMedia();

            dispatch(setExistingImages(response));
        } catch (error) {
            (error.response?.data.message).forEach((value, i) => {
                showNotification(error.response?.data.message[i], 'danger');
            });
        }
    };
};

export const fetchExistingForms = () => {
    return async (dispatch) => {
        try {
            const response = await pageInfoAPI.fetchExistingForms();

            dispatch(setExistingFormsList(response));
        } catch (error) {
            console.log(error);
        }
    };
};

export const fetchExistingMaps = () => {
    return async (dispatch) => {
        try {
            const response = await pageInfoAPI.fetchExistingMaps();

            dispatch(setExistingMapsList(response));
        } catch (error) {
            console.log(error);
        }
    };
};

export const fetchExistingQuizzes = () => {
    return async (dispatch) => {
        try {
            const response = await pageInfoAPI.fetchExistingQuizzes();

            dispatch(setExistingQuizzesList(response));

        } catch (error) {
            console.log(error);
        }
    };
};

export const fetchExistingAssessments = () => {
    return async (dispatch) => {
        try {
            const response = await pageInfoAPI.fetchExistingAssessments();

            dispatch(setExistingAssessmentsList(response));
        } catch (error) {
            console.log(error);
        }
    };
};

export const fetchExistingCalculators = () => {
    return async (dispatch) => {
        try {
            const response = await pageInfoAPI.fetchExistingCalculators();

            dispatch(setExistingCalculatorsList(response));
        } catch (error) {
            console.log(error);
        }
    };
};

export const createModule = (values, moduleType, pageId) => {
    return async (dispatch, getState) => {
        const moduleName = values.name;
        const currentPosition = getState().pageComponents.items.length;

        delete values.name;

        // TODO: make prettier
        const data = {
            name: moduleName,
            type: moduleType,
            content: values,
            page_id: pageId,
            position: currentPosition
        };

        try {
            let response = await modulesAPI.create(data);
            dispatch(endModuleCreation());
            dispatch(fetchModules(pageId));
            // TODO: rewrite on new api response
            showNotification(response.message, 'success', 'shifted');
        } catch (error) {
            // if (error.response?.data?.message) {
            //     showNotification(error.response.data.message, 'danger', 'shifted');
            // } else {
            //     showNotification('Some error occurred', 'danger', 'shifted');
            // }
            console.log(error);
        } finally {
        }
    };
};

export const deleteItem = (index) => {
    return async (dispatch) => {
        try {
            dispatch(deleteItemByIndex(index));
        } catch (error) {
            if (error.response?.data?.message) {
                showNotification(error.response.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
        }
    };
};

export const addExistingModule = (module) => {
    return async (dispatch, getState) => {
        try {
            const pageInfo = getState().pageInfo.pageInfo;
            const currentPosition = getState().pageComponents.items.length;

            // TOOD: make prettier
            const values = {
                name: module.name,
                type: module.type,
                content: module.content,
                copied_id: module.id,
                page_id: pageInfo.id,
                position: currentPosition
            };

            await modulesAPI.create(values);

            dispatch(endExistingComponentAddition());
            dispatch(fetchModules(pageInfo.id));
        } catch (error) { }
    };
};

export const addExistingForm = (form) => {
    return async (dispatch, getState) => {
        const pageInfo = getState().pageInfo.pageInfo;
        const currentPosition = getState().pageComponents.items.length;
        try {
            // TODO: make prettier
            const values = {
                name: form.name,
                receiver: form.receiver,
                theme: form.theme,
                pu_header: form.pu_header,
                pu_text: form.pu_text,
                fields: form.fields,
                width: form.width,
                submit_button: form.submit_button,
                media_large: form.media_large.value ? form.media_large.value : form.media_large,
                media_medium: form.media_medium.value ? form.media_medium.value : form.media_medium,
                media_small: form.media_small.value ? form.media_small.value : form.media_small,
                media_less_640: form.media_less_640.value ? form.media_less_640.value : form.media_less_640,
            };

            await formsAPI.createForm(values, values.fields, values.submit_button, {
                position: currentPosition,
                copied_id: form.id,
                page_id: pageInfo.id
            });

            dispatch(endExistingComponentAddition());
            dispatch(fetchModules(pageInfo.id));
        } catch (error) {
            console.log(error);
        }
    };
};


export const addExistingMap = (map) => {
    return async (dispatch, getState) => {
        const pageInfo = getState().pageInfo.pageInfo;
        const currentPosition = getState().pageComponents.items.length;
        try {


            // TODO: make prettier
            const values = {
                name: map.name,
                items: map.items,
                media_large: map.media_large.value ? map.media_large.value : map.media_large,
                media_medium: map.media_medium.value ? map.media_medium.value : map.media_medium,
                media_small: map.media_small.value ? map.media_small.value : map.media_small,
                media_less_640: map.media_less_640.value ? map.media_less_640.value : map.media_less_640,
                // content: map.content,
                // copied_id: map.id,
                // page_id: pageInfo.id,
                // position: currentPosition
            };

            await itemsAPI.createMap('maps', values, values.items, {
                position: currentPosition,
                copied_id: map.id,
                page_id: pageInfo.id
            });

            dispatch(endExistingComponentAddition());
            dispatch(fetchModules(pageInfo.id));;
        } catch (error) {
            console.log(error);
        }
    };
};

export const addExistingAssessment = (assessment) => {
    return async (dispatch, getState) => {
        const pageInfo = getState().pageInfo.pageInfo;
        const currentPosition = getState().pageComponents.items.length;

        try {


            // TODO: make prettier
            const values = {
                name: assessment.name,
                email:assessment.email,
                items: assessment.items,
                media_large: assessment.media_large.value ? assessment.media_large.value : assessment.media_large,
                media_medium: assessment.media_medium.value ? assessment.media_medium.value : assessment.media_medium,
                media_small: assessment.media_small.value ? assessment.media_small.value : assessment.media_small,
                media_less_640: assessment.media_less_640.value ? assessment.media_less_640.value : assessment.media_less_640,
                description: assessment.description
                // content: map.content,
                // copied_id: map.id,
                // page_id: pageInfo.id,
                // position: currentPosition
            };

            await itemsAPI.createAssessment('self-assessment', values, values.items, {
                position: currentPosition,
                copied_id: assessment.id,
                page_id: pageInfo.id
            });

            dispatch(endExistingComponentAddition());
            dispatch(fetchModules(pageInfo.id));;
        } catch (error) {
            console.log(error);
        }
    };
};

export const addExistingCalculator = (calculator) => {
    return async (dispatch, getState) => {
        const pageInfo = getState().pageInfo.pageInfo;
        const currentPosition = getState().pageComponents.items.length;
        // 
        try {


            // TODO: make prettier
            const values = {
                name: calculator.name,
                items: calculator.items,
                media_large: calculator.media_large.value ? calculator.media_large.value : calculator.media_large,
                media_medium: calculator.media_medium.value ? calculator.media_medium.value : calculator.media_medium,
                media_small: calculator.media_small.value ? calculator.media_small.value : calculator.media_small,
                media_less_640: calculator.media_less_640.value ? calculator.media_less_640.value : calculator.media_less_640,
                // description: calculator.description
                // content: map.content,
                // copied_id: map.id,
                // page_id: pageInfo.id,
                // position: currentPosition
            };

            await itemsAPI.createDrugCalculator('drug-calculator', values, values.items, {
                position: currentPosition,
                copied_id: calculator.id,
                page_id: pageInfo.id
            });

            dispatch(endExistingComponentAddition());
            dispatch(fetchModules(pageInfo.id));;
        } catch (error) {
            console.log(error);
        }
    };
};

export const addExistingQuizz = (quizz) => {
    return async (dispatch, getState) => {
        const pageInfo = getState().pageInfo.pageInfo;
        const currentPosition = getState().pageComponents.items.length;
        try {
            // TODO: make prettier
            const values = {
                name: quizz.name,
                continue_button_text: quizz.continue_button_text,
                finish_button_text: quizz.finish_button_text,
                text_after_finish: quizz.text_after_finish,
                media_large: quizz.media_large.value ? quizz.media_large.value : quizz.media_large,
                media_medium: quizz.media_medium.value ? quizz.media_medium.value : quizz.media_medium,
                media_small: quizz.media_small.value ? quizz.media_small.value : quizz.media_small,
                media_less_640: quizz.media_less_640.value ? quizz.media_less_640.value : quizz.media_less_640,
                // type: quizz.type,
                items: quizz.items,
                // content: quizz.content,
                // copied_id: quizz.id,
                // page_id: pageInfo.id,
                // position: currentPosition
            };

            await itemsAPI.createQuiz('quizzes', values, values.items, {
                position: currentPosition,
                copied_id: quizz.id,
                page_id: pageInfo.id
            });

            dispatch(endExistingComponentAddition());
            dispatch(fetchModules(pageInfo.id));;
        } catch (error) {
            console.log(error);
        }
    };
};

export const updateModule = (values, moduleId, pageId) => {
    return async (dispatch) => {
        const moduleName = values.name;
        delete values.name;

        // TODO: make prettier
        const data = {
            name: moduleName,
            content: values,
            moduleId
        };

        try {
            let response = await modulesAPI.update(data);

            dispatch(removeEditingItemId());
            dispatch(fetchModules(pageId));

            // TODO: rewrite on new api response
            successMessage(response)
        } catch (error) {
            if (error.response?.data?.message) {
                showNotification(error.response.data.message[0], 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
        }
    };
};

export default pageComponentsReducer;
