import { mediaApi } from '../api/api';
import { showNotification } from '../parts/Admin/utils/notifications/notifications';
import { getItems, successMessage } from './actions';


const SET_ACTIVE_IMAGE = 'SET_ACTIVE_IMAGE';
const SET_MODAL_ACTIVE = 'SET_MODAL_ACTIVE';
const DELETE_IMAGE = 'DELETE_MEDIA';
const UPDATE_IMAGE = 'UPDATE_IMAGE';

const ENTITY = 'sharedMedia';
const ENTITY_UPPER_CASE = ENTITY.toUpperCase();

const initialState = {
    items: null,
    extensions: null,
    isModalActive: false,
    pageSize: '15',
    sortField: 'id',
    sortType: 'desc',
    currentPage: 1,
    activeImage: null,
    totalCount: null,
    isFilterOpen: false,
    filterString: '',
    filteredCount: null,
};

const sharedMediaReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SET_ITEMS_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                items: action.data.items,
                totalCount: action.data.total_count,
                filteredCount: action.data.filtered_count
            };
        case `SET_CURRENT_PAGE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                currentPage: action.currentPage
            };
        case `SET_EXTENSIONS_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                extensions: action.payload
            };
        case `SET_FILTER_OPEN_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                isFilterOpen: action.isFilterOpen,
            }
        case `CHANGE_SORT_${ENTITY_UPPER_CASE}`:
            let newSortType = '';

            if (action.oldSortField === action.sortField) {
                if (action.oldSortType === 'asc') {
                    newSortType = 'desc';
                } else {
                    newSortType = 'asc';
                }
            } else {
                newSortType = 'desc';
            }

            return {
                ...state,
                sortField: action.sortField,
                sortType: newSortType
            };
        case `SET_FILTER_STRING_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                filterString: action.filterString
            };
        case `SET_PAGE_SIZE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                pageSize: action.pageSize
            };
        case SET_ACTIVE_IMAGE:
            return {
                ...state,
                activeImage: action.payload
            };
        case SET_MODAL_ACTIVE:
            return { ...state, isModalActive: action.payload };
        case DELETE_IMAGE:
            return {
                ...state,
                items: state.items.filter(({ id }) => id !== action.payload),
                activeImage: null,
                isModalActive: false
            };

        case UPDATE_IMAGE:
            return {
                ...state,
                items: state.items.map((item) => {
                    if (item.id === action.payload.id) {
                        console.log(action.payload);
                        return { ...item, ...action.payload };
                    }
                    return item;
                })
            };
        default:
            return state;
    }
};

export const setActiveImage = (payload) => ({
    type: SET_ACTIVE_IMAGE,
    payload
});

export const setModalActive = (payload) => ({
    type: SET_MODAL_ACTIVE,
    payload
});

const updateImage = (payload) => ({
    type: UPDATE_IMAGE,
    payload
});

const deleteImage = (payload) => ({
    type: DELETE_IMAGE,
    payload
});

const setExtensions = (payload) => ({
    type: `SET_EXTENSIONS_${ENTITY_UPPER_CASE}`,
    payload
});

export const fetchExtensions = () => {
    return async (dispatch) => {
        try {
            const data = await mediaApi.getExtensions();
            dispatch(setExtensions(data));
        } catch (error) {
            console.log(error);
        }
    };
};

export const addNewMedia = (formData, setImage = null) => {
    return async (dispatch) => {
        try {
            const data = await mediaApi.postMedia(formData);
            setImage && setImage(data.message);
            dispatch(getItems(ENTITY));
            successMessage(data)
        } catch (error) {
            console.log(error);
        }
    };
};

export const updateMedia = (id, payload) => {
    return async (dispatch) => {
        try {
            await mediaApi.updateMedia(id, payload);
            dispatch(updateImage({ ...payload, id }));
            dispatch(setModalActive(false));
            dispatch(setActiveImage(null));
            showNotification('File was updated', 'success');
        } catch (error) {
            console.log(error);
        }
    };
};

export const deleteMedia = (id) => {
    return async (dispatch) => {
        try {
            const data = await mediaApi.deleteMedia(id);
            dispatch(deleteImage(id));
            dispatch(setModalActive(false));
            dispatch(setActiveImage(null));
            dispatch(getItems(ENTITY));
            showNotification(data.message[0].toString(), 'success');
        } catch (error) {
            console.log(error);
        }
    };
};

export default sharedMediaReducer;
