
import {menusApi} from "../api/api";
import {showNotification} from "../parts/Admin/utils/notifications/notifications";
import {selectedMenuItemLanguageSet} from "./menu-items-reducer";
import {errorMessage, successMessage } from './actions';

const ENTITY = 'menus';
const ENTITY_UPPER_CASE = ENTITY.toUpperCase();

const MENUS_DATA_SET = 'MENUS_DATA_SET';
const PARENT_LOADING_ID = 'PARENT_LOADING_ID';
const ACTIVE_MENU_ID = 'ACTIVE_MENU_ID';
const PAGES_DATA_LOADING = 'PAGES_DATA_LOADING'
const EDITABLE_SET = 'EDITABLE_SET'
const LANGUAGES_LIST_SET = 'LANGUAGES_LIST_SET'
const SELECTED_LANGUAGE_SET = 'SELECTED_LANGUAGE_SET';

const initialState = {
    items: [],
    itemsLoading: true,
    totalCount: null,
    filteredCount: null,
    item: null,
    currentPage: 1,
    pageSize: '8',
    sortField: 'create_date',
    sortType: 'desc',
    filterString: '',
    menusDataLoading: false,
    languagesList: [],
    isFilterOpen: false,
    usersList: [],
    selectedUser: null,
    selectedLanguage: null,
}

const menusReducer = (state = initialState, action) => {

    switch (action.type) {
        case LANGUAGES_LIST_SET:
            return {
                ...state,
                languagesList: action.data
            }
        case MENUS_DATA_SET:
            return {
                ...state,
                item: action.data.items
            }
        case `SET_ITEMS_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                items: action.data.items,
                totalCount: action.data.total_count,
                filteredCount: action.data.filtered_count
            }
        case `SET_FILTER_OPEN_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                isFilterOpen: action.isFilterOpen,
            }
        case `USERS_LIST_SET_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                usersList: action.data
            };
        case `SELECTED_USER_SET_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                selectedUser: action.data
            };
        case `SELECTED_LANGUAGE_SET`:
                return {
                    ...state,
                    selectedLanguage: action.data
                };
        case `SET_ITEM_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                item: action.data
            }
        case `SET_CURRENT_PAGE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                currentPage: action.currentPage,
            }
        case `CHANGE_SORT_${ENTITY_UPPER_CASE}`:
            let newSortType = '';

            if (action.oldSortField === action.sortField) {
                if (action.oldSortType === 'asc') {
                    newSortType = 'desc';
                } else {
                    newSortType = 'asc';
                }
            } else {
                newSortType = 'desc';
            }

            return {
                ...state,
                sortField: action.sortField,
                sortType: newSortType,
            }
        case `SET_FILTER_STRING_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                filterString: action.filterString
            }
        case `SET_PAGE_SIZE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                pageSize: action.pageSize
            }
        case PARENT_LOADING_ID:
            return {
                ...state,
                parentLoadingId: action.data
            }
        case ACTIVE_MENU_ID:
            return {
                ...state,
                activeMenuId: action.data
            }
        case PAGES_DATA_LOADING:
            return {
                ...state,
                menusDataLoading: action.bool
            }
        case EDITABLE_SET:
            return {
                ...state,
                editable: action.data
            }

        default:
            return state;
    }
}
function makeActionCreator(type, ...argNames) {
    return function (...args) {
        let action = { type }
        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index]
        })
        return action
    }
}

const languagesListSet = makeActionCreator(LANGUAGES_LIST_SET, 'data');
export const selectedLanguageSet = makeActionCreator(SELECTED_LANGUAGE_SET, 'data');

export const fetchLanguages = () => {
    return async (dispatch, getState) => {
        try {

            let data = await menusApi.fetchLanguagesList();

            dispatch(languagesListSet(data.items));
        } catch (error) {
            errorMessage(error)
        } finally {
        }
    };
};

export default menusReducer;