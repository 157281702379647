import { itemsAPI } from '../api/api';
import { showNotification } from '../parts/Admin/utils/notifications/notifications';

const SET_ITEMS_DRUGS = 'SET_ITEMS_DRUGS';
const SET_EDITING_ITEM_ID = 'SET_EDITING_ITEM_ID';
const REMOVE_EDITING_ITEM_ID = 'REMOVE_EDITING_ITEM_ID';
const UPDATE_DRUGS_POSITION = 'UPDATE_DRUGS_POSITION';
const SET_DRUGS_CREATION_TYPE = 'SET_DRUGS_CREATION_TYPE';
const SET_STEP = 'SET_STEP';
const SET_DRUGS_LIST = 'SET_DRUGS_LIST';
const SET_LOADING_STATE = 'SET_LOADING_STATE';
const DRUGS_CREATION_START = 'DRUGS_CREATION_START';
const DRUGS_CREATION_END = 'DRUGS_CREATION_END';
const DELETE_DRUGS = 'DELETE_DRUGS';
const SET_DRUGS_MAP = 'SET_DRUGS_MAP';
const UPDATE_DRUGS_MAP = 'UPDATE_DRUGS_MAP';
const DELETE_DRUGS_MAP = 'DELETE_DRUGS_MAP';
const SET_IS_LOADED = 'SET_IS_LOADED';

const ENTITY = 'drug-calculator';

const initialState = {
    activeItemId: null,
    newDrugsCreation: false,
    drugsCreationActiveStep: 2,
    drugsCreationType: 'drugs',
    itemsLoading: true,
    items: [],
    isLoaded: true,
};

const mapsComponentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DRUGS_LIST:
            return {
                ...state,
                items: action.payload
            };
        case SET_LOADING_STATE:
            return {
                ...state,
                itemsLoading: action.payload
            };
        case SET_IS_LOADED:
            return {
                ...state,
                isLoaded: action.payload
            };
        case SET_ITEMS_DRUGS:
            return {
                ...state,
                items: action.payload
            };
        case SET_EDITING_ITEM_ID:
            
            return {
                ...state,
                activeItemId: action.payload
            };
        case `SET_DRUGS_MAP`:
            const tmpState = {
                ...state
            }
            tmpState.items.push(action.payload);

            return {
                ...state,
                items: [...tmpState.items]
            };
        case `UPDATE_DRUGS_MAP`:
            const tmpState1 = {
                ...state
            }
            const newField = tmpState1.items.find(field => field.id === action.payload.id)
            const index = tmpState1.items.indexOf(newField)
            newField.name = action.payload.name
            newField.type = action.payload.type
            newField.content = action.payload.content
            newField.id = action.payload.id
            newField.active = action.payload.active
            newField.position = action.payload.position

            tmpState1.items[index] = newField
            return {
                ...state,
                items: [...tmpState1.items]
            }
        case DELETE_DRUGS_MAP:
            const tmpState2 = {
                ...state
            }
            const newFields = tmpState2.items.filter((field) => field.id !== action.payload);
            tmpState2.items = newFields
            return {
                ...state,
                items: [...tmpState2.items]
            }
        case REMOVE_EDITING_ITEM_ID:
            return {
                ...state,
                activeItemId: null
            };
        case DRUGS_CREATION_START:
            return {
                ...state,
                newDrugsCreation: true
            };
        case UPDATE_DRUGS_POSITION:
            return {
                ...state,
                items: action.payload.map((item, index) => {
                    item.position = index;
                    return item;
                })
            };
        case DRUGS_CREATION_END:
            return {
                ...state,
                newDrugsCreation: false,
                drugsCreationActiveStep: 2,
                drugsCreationType: 'drugs'
            };
        case SET_DRUGS_CREATION_TYPE:
            return {
                ...state,
                drugsCreationType: action.drugsType
            };
        case SET_STEP:
            return {
                ...state,
                drugsCreationActiveStep: action.step
            };

        case DELETE_DRUGS:
            return {
                ...state,
                items: state.items.filter((_, index) => index !== action.payload)
            };
        default:
            return state;
    }
};

export const setDrugsList = (drugs) => ({
    type: SET_DRUGS_LIST,
    payload: drugs
});


export const setDrugsMap = (data) => ({
    type: SET_DRUGS_MAP,
    payload: data
});

export const updateDrugsMap = (data) => ({
    type: UPDATE_DRUGS_MAP,
    payload: data
});

export const deleteDrugsMap = (data) => ({
    type: DELETE_DRUGS_MAP,
    payload: data
});

export const setEditingItemId = (drugId) => ({
    type: SET_EDITING_ITEM_ID,
    payload: drugId
});

export const removeEditingItemId = () => ({
    type: REMOVE_EDITING_ITEM_ID
});

export const startDrugsCreation = () => ({
    type: DRUGS_CREATION_START
});

export const endDrugsCreation = () => ({
    type: DRUGS_CREATION_END
});

export const setStep = (step) => ({
    type: SET_STEP,
    step
});

export const setDrugsCreationType = (drugsType) => ({
    type: SET_DRUGS_CREATION_TYPE,
    drugsType
});

export const setLoadingState = (bool) => ({
    type: SET_LOADING_STATE,
    payload: bool
});

export const setIsLoaded = (bool) => ({
    type: SET_IS_LOADED,
    payload: bool
});

export const deleteItemByIndex = (index) => ({
    type: DELETE_DRUGS,
    payload: index
});

export const updateDrugsPosition = (items) => ({
    type: UPDATE_DRUGS_POSITION,
    payload: items
});

export const fetchDrugs = (drugId) => {
    return async (dispatch) => {
        try {
            const response = await itemsAPI.getItemData(ENTITY, drugId);

            dispatch(setDrugsList(response.items || []));
        } catch (error) {
            console.log(error);
            dispatch(setDrugsList([]));
        } finally {
            setTimeout(function () {
                dispatch(setLoadingState(false));
            }, 700);

        }
    };
};

export const deleteItem = (index) => {
    return async (dispatch) => {
        try {
            dispatch(deleteItemByIndex(index));
        } catch (error) {
            if (error.response?.data?.message) {
                showNotification(error.response.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
        }
    };
};

export default mapsComponentsReducer;
