import { menusApi, pageInfoAPI } from '../api/api';
import { showNotification } from '../parts/Admin/utils/notifications/notifications';
import history from '../history';
import { fetchPagesData } from './pages-reducer';
import { errorMessage, successMessage } from './actions';

const PAGE_INFO_SET = 'PAGE_INFO_SET';
const PAGE_INFO_LOADING = 'PAGE_INFO_LOADING';
const SET_PAGES_TREE = 'SET_PAGES_TREE';

const PAGES_LIST_SET = 'PAGES_LIST_SET';
const PAGES_LIST_LOADING = 'PAGES_LIST_LOADING';

const LANGUAGES_LIST_SET = 'LANGUAGES_LIST_SET';
const LANGUAGES_LIST_LOADING = 'LANGUAGES_LIST_LOADING';

const GROUPS_LIST_SET = 'GROUPS_LIST_SET';
const GROUPS_LIST_LOADING = 'GROUPS_LIST_LOADING';

const UPDATE_PAGE_LOADING = 'UPDATE_PAGE_LOADING';
const DELETE_PAGE_LOADING = 'DELETE_PAGE_LOADING';

const EDITABLE_SET = 'EDITABLE_SET';
const CHECKBOX_SETTER = 'CHECKBOX_SETTER';

const MAILING_CHECKBOX = 'MAILING_CHECKBOX';
const CHILDREN_LIST_SET = 'CHILDREN_LIST_SET';

const initialState = {
    pageInfo: null,
    pageInfoLoading: false,
    pagesList: [],
    childrenList: [],
    pagesTree: [],
    pagesListLoading: false,
    languagesList: [],
    languagesListLoading: false,
    groupsList: [],
    groupsListLoading: false,
    updatePageLoading: false,
    deletePageLoading: false,
    editable: false,
    checkboxSelector: false,
    mailingCheckbox: null
};

const pageInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAGE_INFO_SET:
            return {
                ...state,
                pageInfo: action.data
            };
        case PAGE_INFO_LOADING:
            return {
                ...state,
                pageInfoLoading: action.bool
            };

        case PAGES_LIST_SET:
            return {
                ...state,
                pagesList: action.data
            };
        case CHILDREN_LIST_SET:
            return {
                ...state,
                childrenList: action.data
            };
        case PAGES_LIST_LOADING:
            return {
                ...state,
                pagesListLoading: action.bool
            };
        case MAILING_CHECKBOX:
            return {
                ...state,
                mailingCheckbox: action.bool
            }
        case LANGUAGES_LIST_SET:
            return {
                ...state,
                languagesList: action.data
            };
        case LANGUAGES_LIST_LOADING:
            return {
                ...state,
                languagesListLoading: action.bool
            };
        case GROUPS_LIST_SET:
            return {
                ...state,
                groupsList: action.data
            };
        case GROUPS_LIST_LOADING:
            return {
                ...state,
                groupsListLoading: action.bool
            };
        case SET_PAGES_TREE:
            return { ...state, pagesTree: action.payload };
        case UPDATE_PAGE_LOADING:
            return {
                ...state,
                updatePageLoading: action.bool
            };
        case DELETE_PAGE_LOADING:
            return {
                ...state,
                deletePageLoading: action.bool
            };
        case EDITABLE_SET:
            return {
                ...state,
                editable: action.data
            };
        case CHECKBOX_SETTER:
            return {
                ...state,
                checkboxSelector: action.bool
            }
        default:
            return state;
    }
};

function makeActionCreator(type, ...argNames) {
    return function (...args) {
        let action = { type };
        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index];
        });
        return action;
    };
}

export const mailingCheckboxSet = makeActionCreator(MAILING_CHECKBOX, 'bool');
export const pageInfoSet = makeActionCreator(PAGE_INFO_SET, 'data');
const pageInfoLoading = makeActionCreator(PAGE_INFO_LOADING, 'bool');
const pagesListSet = makeActionCreator(PAGES_LIST_SET, 'data');
export const childrenListSet = makeActionCreator(CHILDREN_LIST_SET, 'data');
const pagesListLoading = makeActionCreator(PAGES_LIST_LOADING, 'bool');
const languagesListSet = makeActionCreator(LANGUAGES_LIST_SET, 'data');
const languagesListLoading = makeActionCreator(LANGUAGES_LIST_LOADING, 'bool');
const groupsListSet = makeActionCreator(GROUPS_LIST_SET, 'data');
const groupsListLoading = makeActionCreator(GROUPS_LIST_LOADING, 'bool');
const updatePageLoading = makeActionCreator(UPDATE_PAGE_LOADING, 'bool');
const deletePageLoading = makeActionCreator(DELETE_PAGE_LOADING, 'bool');
export const editableSet = makeActionCreator(EDITABLE_SET, 'data');
const setPagesTree = makeActionCreator(SET_PAGES_TREE, 'payload');
export const checkboxChanger = makeActionCreator(CHECKBOX_SETTER, 'bool');

export const getPagesTree = (language_id) => {
    return async (dispatch, getState) => {
        const pageInfo = getState().pageInfo.pageInfo;
        try {
            let data = await menusApi.getPages();
            if (language_id !== undefined) {
                data = data.filter((i) => i.id != language_id && i.pages.length > 0);
            } else {
                data = data.filter((i) => i.id != pageInfo.language_id && i.pages.length > 0);
            }
            dispatch(setPagesTree(data));
        } catch (error) {
            (error.response?.data.message)?.forEach((value, i) => {
                showNotification(error.response?.data.message[i], 'danger');
            });
        } finally {
        }
    };
};

export const fetchPageInfo = (id) => {
    return async (dispatch, getState) => {

        try {

            dispatch(pageInfoLoading(true));
            let data = await pageInfoAPI.fetchPageInfo(id);
            // dispatch(pageInfoSet(data));
            dispatch(fetchLanguagesList(data.language_id));
            dispatch(mailingCheckboxSet(data.mailing_list));
        } catch (error) {
            (error.response?.data.message).forEach((value, i) => {
                showNotification(error.response?.data.message[i], 'danger');
            });
        } finally {
            dispatch(pageInfoLoading(false));
        }
    };
};

export const fetchPagesList = (language_id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(pagesListLoading(true));
            let data = await pageInfoAPI.fetchPagesList(language_id);
            dispatch(pagesListSet(data.items));
        } catch (error) {
            (error.response?.data.message).forEach((value, i) => {
                showNotification(error.response?.data.message[i], 'danger');
            });
        } finally {
            dispatch(pagesListLoading(false));
        }
    };
};

export const fetchChildrenList = (parent_id) => {
    return async (dispatch, getState) => {
        try {
            let data = await pageInfoAPI.fetchChildrenList(parent_id);
            dispatch(childrenListSet(data.items));
        } catch (error) {
            (error.response?.data.message).forEach((value, i) => {
                showNotification(error.response?.data.message[i], 'danger');
            });
        } finally {
        }
    };
};

export const fetchLanguagesList = (currentLangId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(languagesListLoading(true));
            let data = await pageInfoAPI.fetchLanguagesList();
            dispatch(languagesListSet(data.items));
            dispatch(fetchPagesList(currentLangId || data.items[0].id));
        } catch (error) {
            (error.response?.data.message).forEach((value, i) => {
                showNotification(error.response?.data.message[i], 'danger');
            });
        } finally {
            dispatch(languagesListLoading(false));
        }
    };
};

export const fetchGroupsList = () => {
    return async (dispatch) => {
        try {
            dispatch(groupsListLoading(true));
            let data = await pageInfoAPI.fetchGroupsList();
            dispatch(groupsListSet(data.items));
        } catch (error) {
            (error.response?.data.message).forEach((value, i) => {
                showNotification(error.response?.data.message[i], 'danger');
            });
        } finally {
            dispatch(groupsListLoading(false));
        }
    };
};

export const updatePage = (id, values, apply) => {
    return async (dispatch, getState) => {
        try {
            
            dispatch(updatePageLoading(true));

            const items = getState().pageComponents.items;

            
            const content = {
                modules: {},
                forms: {},
                maps: {},
                quizzes: {},
                assessmentResult: {},
                selfAssessment: {},
                drugCalculator:{}
            };

            
            items.forEach((item) => {
                // TODO: replace with object field
                const type = item.group_type;
                if (type === 'drug-calculators'){
                    content['drugCalculator'][item.id] = item.position;
                } else if (type === 'self-assessments'){
                    content['selfAssessment'][item.id] = item.position;
                } else {
                content[type][item.id] = item.position;
                }
            });
            values.append('content', JSON.stringify(content));


            let data = await pageInfoAPI.updatePage(id, values);
            successMessage(data);
            if (!apply) {
                history.push('/admiral-admin/pages');
            } else {
                dispatch(fetchPageInfo(id))
            }
        } catch (error) {
            errorMessage(error)
        } finally {
            dispatch(updatePageLoading(false));
        }
    };
};

export const createPage = (values) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updatePageLoading(true));
            let data = await pageInfoAPI.createPage(values);
            successMessage(data)
            dispatch(fetchPagesData());
            history.push('/admiral-admin/pages');
        } catch (error) {
            errorMessage(error)
        } finally {
            dispatch(updatePageLoading(false));
        }
    };
};

export const duplicatePage = (item) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updatePageLoading(true));
            let data = await pageInfoAPI.duplicatePage(item);
            successMessage(data)
            dispatch(fetchPagesData());
            history.push('/admiral-admin/pages');
        } catch (error) {
            errorMessage(error)
        } finally {
            dispatch(updatePageLoading(false));
        }
    };
};

export const deletePage = (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(deletePageLoading(true));
            let data = await pageInfoAPI.deletePage(id);
            successMessage(data)
            dispatch(fetchPagesData());
            history.push('/admiral-admin/pages');
        } catch (error) {
            errorMessage(error)
        } finally {
            dispatch(deletePageLoading(false));
        }
    };
};

export default pageInfoReducer;


