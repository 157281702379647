import { formsAPI } from '../api/api';
import { getItems, errorMessage, successMessage, getItemData } from './actions';
import { showNotification } from '../parts/Admin/utils/notifications/notifications';
import history from '../history';
import { toast } from 'react-toastify';
import { StyledTextToast } from '../parts/Admin/components/Main/AdminToastContainer'

const ENTITY = 'forms';
const ENTITY_UPPER_CASE = ENTITY.toUpperCase();
const SET_FIELD_FORM = `SET_FIELD_FORM`;
const UPDATE_FIELD_FORM = `UPDATE_FIELD_FORM`;
const UPDATE_SUBMIT_BUTTON = `UPDATE_SUBMIT_BUTTON`;
const DELETE_FIELD = `DELETE_FIELD`;
const SET_ITEM_FORM = 'SET_ITEM_FORM';
const SENDING_FORM = 'SENDING_FORM';
const HIDING_FILE_TEXT = 'HIDING_FILE_TEXT';

const initialState = {
    items: null,
    fields: [],
    options: [],
    itemsLoading: true,
    totalCount: null,
    filteredCount: null,
    item: {
        fields: [],
        submitButton: {
            name: "Submit Button",
            color: "green",
            textAlign: "center"
        }
    },
    currentPage: 1,
    pageSize: '8',
    sortField: 'create_date',
    sortType: 'desc',
    filterString: '',
    isFilterOpen: false,
    usersList: [],
    selectedUser: null,
    sendingForm: true,
    hideFileText: false
}


const formsReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SET_ITEMS_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                items: action.data.items,
                totalCount: action.data.total_count,
                filteredCount: action.data.filtered_count
            }
        case `SET_ITEM_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                item: action.data
            }
        case `SET_ITEM_FORM`:

            const tmpStatee = {
                ...state
            }
            tmpStatee.item.fields = action.payload;

            return {
                ...state,
                item: { ...tmpStatee.item }
            }
        case `SET_FILTER_OPEN_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                isFilterOpen: action.isFilterOpen,
            }
        case `USERS_LIST_SET_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                usersList: action.data
            };
        case `SELECTED_USER_SET_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                selectedUser: action.data
            };
        case `SET_FIELD_FORM`:
            const tmpState = {
                ...state
            }
            tmpState.item.fields.push(action.payload);
            return {
                ...state,
                item: { ...tmpState.item }
            }
        case `DELETE_FIELD`:
            const tmpState2 = {
                ...state
            }
            const newFields = tmpState2.item.fields.filter((field) => field.fieldId !== action.payload);
            tmpState2.item.fields = newFields
            return {
                ...state,
                item: { ...tmpState2.item }
            }
        case `UPDATE_FIELD_FORM`:
            const tmpState1 = {
                ...state
            }
            const newField = tmpState1.item.fields.find(field => field.fieldId === action.payload.fieldId)
            const index = tmpState1.item.fields.indexOf(newField)
            newField.name = action.payload.name
            newField.nameLatin = action.payload.nameLatin
            newField.required = action.payload.required
            newField.validation = action.payload.validation
            newField.message = action.payload.message
            newField.contentPart = action.payload.contentPart
            newField.type = action.payload.type
            newField.placeholder = action.payload.placeholder
            newField.options = action.payload.options

            tmpState1.item.fields[index] = newField
            return {
                ...state,
                item: { ...tmpState1.item }
            }
        case `UPDATE_SUBMIT_BUTTON`:

            const tmpState3 = {
                ...state
            }

            tmpState3.item.submit_button = action.payload
            return {
                ...state,
                item: { ...tmpState3.item }
            }
        case `SET_CURRENT_PAGE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                currentPage: action.currentPage,
            }
        case `CHANGE_SORT_${ENTITY_UPPER_CASE}`:
            let newSortType = '';

            if (action.oldSortField === action.sortField) {
                if (action.oldSortType === 'asc') {
                    newSortType = 'desc';
                } else {
                    newSortType = 'asc';
                }
            } else {
                newSortType = 'desc';
            }

            return {
                ...state,
                sortField: action.sortField,
                sortType: newSortType,
            }
        case `SET_FILTER_STRING_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                filterString: action.filterString
            }
        case `SET_PAGE_SIZE_${ENTITY_UPPER_CASE}`:
            return {
                ...state,
                pageSize: action.pageSize
            }
        case 'SENDING_FORM':
            return {
                ...state,
                sendingForm: action.payload
            }
        case 'HIDING_FILE_TEXT':
            return {
                ...state,
                hideFileText: action.payload
            }
        default:
            return state;
    }
}

export const hidingText = (payload) => ({
    type: 'HIDING_FILE_TEXT',
    payload: payload
})

export const sendingForm = (payload) => ({
    type: SENDING_FORM,
    payload: payload
})

export const setFieldForm = (data) => ({
    type: SET_FIELD_FORM,
    payload: data
});

export const updateFieldForm = (data) => ({
    type: UPDATE_FIELD_FORM,
    payload: data
});

export const updateSubmitButton = (data) => ({
    type: UPDATE_SUBMIT_BUTTON,
    payload: data
});

export const deleteField = (data) => ({
    type: DELETE_FIELD,
    payload: data
});
export const setItemForm = (data) => ({
    type: SET_ITEM_FORM,
    payload: data
});

export const createItem = (values, fields, submitButton) => {
    return async (dispatch) => {
        try {
            let data = await formsAPI.createForm(values, fields, submitButton)
            dispatch(getItems(ENTITY));
            successMessage(data)
            history.push(`/admiral-admin/${ENTITY}`);
        } catch (error) {
            errorMessage(error)
        } finally {
        }
    }
};

export const sendResult = (formData, f, { form }) => {
    return async (dispatch) => {
        const toastText = () => (
            <StyledTextToast> <p>{form.pu_header ? form.pu_header : 'Thanks!'}</p> <span>{form.pu_text ? form.pu_text : 'Our manager will soon connect with You!'}</span> </StyledTextToast>
        )
        try {
            dispatch(sendingForm(false));
            let data = await formsAPI.sendResult(formData)
            toast(toastText, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnFocusLoss: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            f.restart();
        } catch (error) {
            toast.error(`${error.response.data.message ? error.response?.data.message : 'Something went wrong'}`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnFocusLoss: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
            console.log(error);
            f.restart();
        } finally {
            dispatch(sendingForm(true));
        }
    }
};

export const updateItem = (id, values, fields, submitButton, apply) => {
    return async (dispatch) => {
        try {
            let data = await formsAPI.updateForm(id, values, fields, submitButton)
            dispatch(getItems(ENTITY));
            successMessage(data)
            if(!apply){
                history.push(`/admiral-admin/${ENTITY}`);
            } else {
                dispatch(getItemData(ENTITY, id))
            }
        } catch (error) {
            errorMessage(error)
        } finally {
        }
    }
};


export default formsReducer;