import { authAPI, itemsAPI } from '../api/api';
import { showNotification } from '../parts/Admin/utils/notifications/notifications';
import history from '../history';
import { errorMessage, successMessage } from './actions';

const SET_USER_DATA = 'SET_USER_DATA';
const SET_USER_DATA_LOADING = 'SET_USER_DATA_LOADING';
const SET_LOGIN_FORM_LOADING = 'SET_LOGIN_FORM_LOADING';
const SET_IS_ADMIN_ICON_OPEN = 'SET_IS_ADMIN_ICON_OPEN';
const SET_IS_FORGOT_PAGE = 'SET_IS_FORGOT_PAGE'
const SET_IS_AUTH = 'SET_IS_AUTH'
const SET_EDIT_MODE = 'SET_EDIT_MODE'
const SET_INVITED_USERS = 'SET_INVITED_USERS'

const initialState = {
    id: null,
    username: null,
    email: null,
    avatar: null,
    phone: null,
    fio: null,
    isAuth: false,
    show_tips: false,
    loginFormLoading: false,
    userDataLoading: true,
    isForgot: false,
    isAdminIconOpen: false,
    isForgotPage: false,
    editMode: false,
    invitedUsers: []
};

const AutReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                ...action.payload
            };
        case SET_USER_DATA_LOADING:
            return {
                ...state,
                userDataLoading: action.payload
            };
        case SET_LOGIN_FORM_LOADING:
            return {
                ...state,
                loginFormLoading: action.val
            };
        case SET_IS_ADMIN_ICON_OPEN:
            return {
                ...state,
                isAdminIconOpen: !state.isAdminIconOpen
            }
        case SET_EDIT_MODE:
            return {
                ...state,
                editMode: !state.editMode
            }
        case SET_IS_FORGOT_PAGE:
            return {
                ...state,
                isForgotPage: action.payload
            }
        case SET_IS_AUTH:
            return {
                ...state,
                isAuth: action.payload
            }
        case SET_INVITED_USERS:
            return {
                ...state,
                invitedUsers: action.payload
            }
        default:
            return state;
    }
};

export const setUserData = (id, email, avatar, username, fio, phone, show_tips, isAuth) => ({
    type: SET_USER_DATA,
    payload: { id, email, avatar, username, fio, phone, show_tips, isAuth }
});
export const setLoginFormLoading = (val) => ({ type: SET_LOGIN_FORM_LOADING, val });

export const setUserDataLoading = (data) => ({
    type: SET_USER_DATA_LOADING,
    payload: data
});

export const setIsAuth = (data) => ({
    type: SET_IS_AUTH,
    payload: data
})

export const setInvitedUsers = (data) => ({
    type: SET_INVITED_USERS,
    payload: data
})

// export const getUserData = () => {
//     return (dispatch, getState) => {
//         dispatch(setUserDataLoading(true));
//         authAPI.getUserData()
//             .then((data) => {
//                 if (data.statusCode === 66) {
//                     showNotification(data.responseMessage, 'danger', true);
//                 } else {
//                     console.log(data);
//                     dispatch(setUserData(data.id, data.email, data.username, data.fio, data.phone, data.show_tips, true));
//                 }
//             })
//             .catch((error) => {
//                 // console.log(error)
//             })
//             .finally(() => {
//                 // dispatch(itemsLoading(false));
//                 dispatch(setUserDataLoading(false));
//             })
//     }
// };

export const toggleAdminIcon = (isAdminIconOpen) => ({
    type: SET_IS_ADMIN_ICON_OPEN,
    payload: isAdminIconOpen
});

export const editModeSet = (setEditMode) => ({
    type: SET_EDIT_MODE,
    payload: setEditMode
})

export const setIsForgotPage = (data) => ({
    type: SET_IS_FORGOT_PAGE,
    payload: data
});

export const getProfile = () => {
    return (dispatch, getState) => {
        authAPI.getProfile()
            .then((data) => {

                dispatch(
                    setUserData(
                        data.id,
                        data.email,
                        data.avatar,
                        data.username,
                        data.fio,
                        data.phone,
                        data.show_tips,
                        true
                    )
                );
            })
            .catch((error) => { })
            .finally(() => { });
    };
};

export const getPermissions = () => {
    return (dispatch, getState) => {
        dispatch(setUserDataLoading(true));

        authAPI
            .getPermissions()
            .then((data) => {
                dispatch(setIsAuth(true));
                dispatch(getProfile());
            })
            .catch((error) => {
                history.replace('/admiral-admin/login');
            })
            .finally(() => {
                dispatch(setUserDataLoading(false));
            });
    };
};

export const getInvitedUsers = () => {
    return async (dispatch) => {
        try {
            let data = await authAPI.getInvitedUsers();
            dispatch(setInvitedUsers(data));
        } catch (error) {

            // errorMessage(error)
        } finally {
        }
    };
};

export const restore = (password, hash) => {
    return (dispatch, getState) => {
        authAPI
            .restore(password, hash)
            .then((data) => {
                showNotification('Password successfully changed', 'success');
                history.push('/admiral-admin/login');
            })
            .catch((error) => {
                (error.response?.data.message).forEach((value, i) => {
                    showNotification(error.response?.data.message[i], 'danger');
                });
            })
            .finally(() => { });
    };
};

export const forgot = ({ email }) => {
    return (dispatch, getState) => {
        authAPI
            .forgot({ email })
            .then((data) => {
                showNotification('We have sent you an email with next steps', 'success');
                dispatch(setIsForgotPage(false))
            })
            .catch((error) => {
                (error.response?.data.message).forEach((value, i) => {
                    showNotification(error.response?.data.message[i], 'danger');
                });
            })
            .finally(() => { });
    };
};

export const login = ({ username, password }) => {
    return (dispatch, getState) => {
        dispatch(setLoginFormLoading(true));
        authAPI
            .login({ username, password })
            .then((data) => {
                dispatch(getPermissions());
                //dispatch(setUserData(null, null, null, null, null, true));

                history.push('/admiral-admin/pages');
                showNotification(data.result, 'success');
            })
            .catch((error) => {
                console.log(error)
                // errorMessage(error)
                // error.response?.data.message.join()
                // // (error.response?.data.message).forEach((value, i) => {
                //     showNotification(error.response?.data.message.join(), 'danger');
                // // });

            })
            .finally(() => {
                dispatch(setLoginFormLoading(false));
            });
    };
};

export const updateItem = (entity, id, formData) => {
    return async (dispatch) => {
        try {
            let data = await itemsAPI.updateUser(entity, id, formData);

            // dispatch(getItems(entity));
            dispatch(getProfile());
            successMessage(data)
        } catch (error) {
            errorMessage(error)
        } finally {
        }
    };
};


export const logout = () => {
    return (dispatch, getState) => {
        authAPI
            .logout()
            .then((data) => {
                if (data.statusCode === 0) {
                    showNotification(data.message, 'danger');
                } else {
                    dispatch(setUserData(null, null, null, null, null, null, false, false));
                    history.replace('/admiral-admin/login');
                }
                successMessage(data)
            })
            .catch((error) => {
            })
            .finally(() => {
                // dispatch(itemsLoading(false));
            });
    };
};

export default AutReducer;
