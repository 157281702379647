import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import AppReducer from './app-reducer';
import AuthReducer from './auth-reducer';
import publicReducer from './public-reducer';
import themesReducer from './themes-reducer';
import formsReducer from './forms-reducer';
import formResultsReducer from './formResults-reducer';
import languagesReducer from './languages-reducer';
import pagesReducer from './pages-reducer';
import pageInfoReducer from './pageInfo-reducer';
import pagesTableReducer from './pages-table-reducer';
import siteSettingsReducer from './site-settings-reducer';
import socialNetworksReducer from './social-networks-reducer';
import usersReducer from './users-reducer';
import menusReducer from './menus-reducer';
import menuItemsReducer from './menu-items-reducer';
import modulesReducer from './modules-reducer';
import mapsReducer from './maps-reducer';
import subscribersReducer from './subscribers-reducer';
import subscriberGroupsReducer from './subscriberGroups-reducer';
import quizzesReducer from './quizzes-reducer';
import pageComponentsReducer from './pageComponents-reducer';
import mapsComponentsReducer from './mapsComponents-reducer';
import quizzesComponentsReducer from './quizzesComponents-reducer';
import sharedMediaReducer from './sharedMedia-reducer';
import schedulesReducer from './schedules-reducer';
import mailingReducer from './mailing-reducer';
import sharePostReducer from './sharing-post/sharingReduccer';
import subscriptionSettingsReducer from './subscription-settings-reducer'
import drugCalculatorReducer from './drugCalculator-reducer'
import drugCalculatorComponentsReducer from './drugCalculatorComponents-reducer'
import selfAssessmentReducer from './self-assessment-reducer';
import selfAssessmentComponentsReducer from './self-assessment-components-reducer';
import selfAssessmentResultReducer from './self-assessment-result-reducer'


let reducers = combineReducers({
    pages: pagesReducer,
    themes: themesReducer,
    languages: languagesReducer,
    forms: formsReducer,
    app: AppReducer,
    auth: AuthReducer,
    public: publicReducer,
    pageInfo: pageInfoReducer,
    pagesTable: pagesTableReducer,
    users: usersReducer,
    siteSettings: siteSettingsReducer,
    subscriptionSettings: subscriptionSettingsReducer,
    menus: menusReducer,
    menuItems: menuItemsReducer,
    modules: modulesReducer,
    pageComponents: pageComponentsReducer,
    sharedMedia: sharedMediaReducer,
    formResults: formResultsReducer,
    sharePost: sharePostReducer,
    selfAssessment: selfAssessmentReducer,
    selfAssessmentResult: selfAssessmentResultReducer,
    maps: mapsReducer,
    drugCalculator: drugCalculatorReducer,
    drugCalculatorComponents: drugCalculatorComponentsReducer,
    quizzes: quizzesReducer,
    quizzesComponents: quizzesComponentsReducer,
    mapsComponents: mapsComponentsReducer,
    selfAssessmentComponents: selfAssessmentComponentsReducer,
    subscribers : subscribersReducer,
    subscriberGroups : subscriberGroupsReducer,
    schedules : schedulesReducer,
    mailing: mailingReducer,
    socialNetworks: socialNetworksReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware)));

export default store;
