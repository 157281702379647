import { showNotification } from '../parts/Admin/utils/notifications/notifications';
import {pageInfoAPI, siteSettingsAPI} from '../api/api';


const SET_DATA = 'SET_DATA';
const LANGUAGES_LIST_SET = 'LANGUAGES_LIST_SET';

const initialState = {
    logo: {},
    name: {},
    copyright: '',
    phone: '',
    address: '',
    email: '',
    favicon : null,
    languagesList:[],
};


function makeActionCreator(type, ...argNames) {
    return function (...args) {
        let action = { type };
        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index];
        });
        return action;
    };
}

const siteSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA:
            return {
                ...state,
                ...action.payload
            };
        case LANGUAGES_LIST_SET:
            return {
                ...state,
                languagesList: action.data
            };
        default:
            return state;
    }
};

const languagesListSet = makeActionCreator(LANGUAGES_LIST_SET, 'data');
export const setData = (data) => ({
    type: SET_DATA,
    payload: data
});



export const setSiteSettings = (formData) => {
    return async (dispatch) => {
        try {
            const data = await siteSettingsAPI.updateSiteSettings(formData);
            await dispatch(getSiteSettings());
            showNotification(data.message[0], 'success', 'shifted');
        } catch (error) {
            if (error.response?.data?.message) {
                showNotification(error.response.data.message[0], 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
        }
    };
};
export const fetchLanguages = (setFiles) => {
    return async (dispatch, getState) => {
        try {
            let data = await pageInfoAPI.fetchLanguagesList();
            setFiles(new Array(data.items.length));
            dispatch(languagesListSet(data.items));
        } catch (error) {
            if (error.data?.message) {
                showNotification(error.data.message, 'danger', 'shifted');
            } else {
                showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {

        }
    };
};

export const getSiteSettings = (setFiles) => {
    return async (dispatch) => {
        try {
            let data = await siteSettingsAPI.getSiteSettings();

            dispatch(setData(data));

        } catch (error) {
            if (error.response?.data?.message) {
                // showNotification(error.response.data.message[0], 'danger', 'shifted');
            } else {
                // showNotification('Some error occurred', 'danger', 'shifted');
            }
            console.log(error);
        } finally {
        }
    };
};

export default siteSettingsReducer;
