import styled from "styled-components";
import { ToastContainer } from 'react-toastify';

export const CustomToast = styled(ToastContainer).attrs({
    // custom props
  })`
    top: 3.5em;
    width: inherit;
    min-width: 290px;
    max-width: 330px;

    .Toastify__toast {
          font-size: 14px;
          color: #212121;
          background-color: white;
          font-family: E-Ukraine;
          font-weight: 400;
            box-shadow: 0px 2px 7px rgb(0 0 0 / 12%);
          border-radius: 15px;
          opacity: 1;
          min-height: 45px;
          padding: 6px 15px;
      }
      .Toastify__toast-body {
        width: min-content;
        z-index: 9999999;
      }
  
    .Toastify__progress-bar {
      background: #31c6538f;
      // background: #4177ff85;
      height: 100%;
    }
    .Toastify__close-button {
      color: white;
      margin-top: 6px;
      background: #0ab632;
      opacity: 1;
      border-radius: 20px;
      width: 20px;
      height: 20px;
      align-items: center;
      display: flex;
      justify-content: center;
      box-shadow: 0px 2px 7px rgb(0 0 0 / 12%);
      padding: 5px 4px 5px 5px;
    }

    
    .Toastify__toast--error {
      // border: 2px solid red;
      .Toastify__progress-bar {
        background: #e72d2d99;
      }
      .Toastify__close-button {
        background: #e72d2dcc;
       
        padding: 5px 4px 5px 5px;
      }
      .Toastify__toast-body {
        z-index: 9999999;
      }
    }
    .Toastify__toast--warning {
    }
    .Toastify__toast--success {}
  `;
  
  export const StyledTextToast = styled.div`
  text-align: center;
    p {
      color:#939597;
      margin-bottom: 0;
      font-size: 27px;
      text-align: center;
    }
    span {
      font-size: 18px;
      color: black;
    }
  `
  
  